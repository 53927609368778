import React from "react";
import { pure } from "recompose";
import { H1, Button } from "../../../Atoms";
import "./ReadyToInvestSection.css";

const ReadyToInvestSection = props => (
    <div className="ReadyToInvestSection centerText withBackground">
        <H1 className="fontSizeLL paddingTopXXL">Ready to invest?</H1>
        <div className="paddingTopL paddingBottomM">
        <a target="_blank" href='https://outlook.office365.com/owa/calendar/GinkgoMortgageInvestmentCorp@ibusinesspower.com/bookings/s/fLlEmknzm0ymp82gIKIbFA2'><Button>SIGN UP</Button></a>
        </div>
        <div className="paddingTopS fontWeightNormal plainText fontSizeXS lineHeightM fadeInUp">
            Our products are easily purchased through an Exempt Market Dealer. We will set up an introductory meeting to
            ensure you are suitable for a Ginkgo investment.
        </div>
    </div>
);

export default pure(ReadyToInvestSection);
