import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import carots from './carots.png'
import './BodySection.css'

const BodySection = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className='BodySection'>
            <div className={`ourStory fadeInUp darkBackground lightText ${!isMobile ? 'paddingXL pageMargin roundedBig' : 'paddingL'}`}>
                <div className={!isMobile ? 'paddingRightXL paddingLeftXL' : ''}>
                    <H1 className={`centerText ${!isMobile ? 'paddingBottomM' : ''}`}>Our Story</H1>
                    <p className='fontSizeS'>
                        Ginkgo Mortgage Investment Corporation is an established, Canada-wide alternative lender helping borrowers solve tough financial problems, while offering investors steady financial growth through high-yielding mortgages.
                    </p>
                    <p className='fontSizeS'>
                        Our vision is to be a prominent leader of the private mortgage industry by providing comprehensive solutions to all stakeholders.
                    </p>
                </div>
            </div>
 {/*           <div className='pagePadding paddingBottomXL'>
                <div className={`row lineHeightM ${!isMobile ? 'paddingTopXL paddingBottomXL' : 'paddingTopL'}`}>
                    <div className='col1'>
                        <OnScrollAnimatior animation='slideInLeft' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <H1 className='centerText'>Giving Back</H1>
                        </OnScrollAnimatior>
                    </div>

                    <div className='col1 fontSizeS'>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <p>
                                At Ginkgo, we practice gratitude and recognize the importance of community when achieving success. We give back yearly by volunteering at Second Harvest, a Toronto non-profit organization committed to increasing accessibility to fresh food while reducing food waste.
                        </p>
                            <p>
                                Our staff have worked at the Second Harvest warehouse as a way of team building, sorting fresh produce that would otherwise be in landfills simply for not meeting grocery store standard aesthetics.
                        </p>
                            <p>
                                As a result, we have contributed to providing over 16,500 meals and hundreds of pounds of fresh produce from going to waste since the start of our initiative in 2016.
                        </p>
                        </OnScrollAnimatior>
                        <div className='paddingTopL'>
                            <img className='roundedBig carotsImage' src={carots} alt='' />
                        </div>

                        <div className="paddingTopL">
                        <iframe width="720" height="500" src="https://www.youtube.com/embed/b6iJjulWbvY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
         
                    </div>
                </div>
            </div>
    */}
        </div>
    )
}
export default pure(BodySection)