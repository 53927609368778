import React from "react";
import { pure } from "recompose";
import { H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./FAQSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";
import { commonQuestions } from "./FAQSectionConstants.js";

const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const QuestionCards = () =>
        commonQuestions.map(({ question, answer }, i) => (
            <OnScrollAnimatior
                key={i}
                animation="fadeInUp"
                delay={i * 100}
                preAnimationStyles={{ opacity: 0 }}
                isMobile={isMobile}
                onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
            >
                <SingleCard question={question} answer={answer} />
            </OnScrollAnimatior>
        ));

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <H1 className="fontSizeLL centerText marginOnMobile">We solved your most commonly asked questions</H1>
            <Grid className="paddingTopXL marginTopOnMobile" container justify="center">
                <Grid item>
                    <Button>COMMON QUESTIONS</Button>
                </Grid>
                <Grid className="paddingLeftM marginTopOnMobile" item>
                    <Button className="whiteButton">FINANCIAL QUESTIONS</Button>
                </Grid>
                {/*<Grid className="paddingLeftM" item>*/}
                {/*    <Button className="whiteButton">APPLICATIONS</Button>*/}
                {/*</Grid>*/}
            </Grid>
            <div className="pagePadding-Investor paddingTopXL marginTopOnMobile">{QuestionCards()}</div>
        </div>
    );
};

export default pure(ReportSection);
