import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../../Atoms";
import "./TimelineCard.css";
const defaultValues = {
    number: 1,
    title: "Bank-level Service",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
};

const TimelineCard = props => (
    <div className="fadeInUp">
        <div className="">
            <H2 className="NumberBullet centerText">{props.number || defaultValues.number}</H2>
        </div>

        <H2 className="lineHeightM fontSizeMM paddingTopSM centerText OrangeTitle">
            {props.title || defaultValues.title}
        </H2>
        <div className="bodyText fontSizeS lineHeightM fontWeightNormal paddingTopSM">
            {props.text || defaultValues.text}
        </div>
    </div>
);

export default pure(TimelineCard);
