import React from 'react'
import { pure } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import mapProps from '../../mapProps.js'
import marker from './marker.svg'

const position = {
    lat: 43.761173,
    lng: -79.355530,
}

const MyGoogleMap = withScriptjs(
    withGoogleMap(
        (props) => {
            return (
                <GoogleMap
                    {...mapProps({center: position})}
                >
                    <Marker
                        position={position}
                        icon={marker}
                    />
                </GoogleMap>
            )
        }
    )
)

export default pure(MyGoogleMap)