import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../Atoms";
import "./PastPortfolioSection.css";

import Grid from "@material-ui/core/Grid";

const PastPortfolioSection = props => (
    <div className="PastPortfolioSection withBackground lightText">
        <Grid container direction="row" justify="center">
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
                <div className="innerContent paddingTopL">
                    <H1 className="fontSizeLL paddingTopXXL">Past Portfolio Reports</H1>
                    <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
                        Browse through our monthly performances. When you're confident with our disclosures and ready to
                        invest, give us a call!
                    </div>
                    <div className="buttonRow">
                        <a href = "mailto: info@ginkgomic.com" target="_blank">Send Email</a>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
);

export default pure(PastPortfolioSection);
