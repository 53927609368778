import React from 'react'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { QuestionAndAnswer, OnScrollAnimatior } from '../../../../Molecules'
import { H2, Button } from '../../../../Atoms'

import './QuestionsSection.css'

const questionsAndAnswers = [
    {
        question: 'What is a Power Agent?',
        answer: `A Power Agent is exactly what it appears to be: an agent who is powerful and amazing in what they do, namely YOU!
		Our success is made possible from great long-term partnerships. We at Ginkgo MIC want to acknowledge the hard work 
		and success from every agent; we understand that they also desire to relax and have fun as regular human beings.
		With that in mind, we have created a great incentive program to make sure you can work hard and also play hard too!`
    },
    {
        question: 'How do I become a Power Agent?',
        answer: `Each deal you fund will earn you points, and no special registration or fee is required! 
		All you have to do to reach Power Agent status is have 3 or more deals submitted and funded at Ginkgo with a minimum total value of 300,000 points.
		Power agents can then level up to Elite Agents with additional 300,000 points and reap more benefits!`
    },
    {
        question: 'What deals are eligible',
        answer: <div>
            <div>Eligible if deals:</div>
            <ul>
                <li>First Mortgages (1 point for every $4 funded)</li>
                <li>Second Mortgages - (1 point for every $1 funded)</li>
                <li>Bundle Mortgages</li>
            </ul>
            <br />
            <div>Example:</div>
            <div>$400,000 First Mortgage = 100,000 points</div>
            <div>$100,000 Second Mortgage = 100,000 points</div>
            <div>Total = 200,000 points </div>
            <br />

            <div>EXCLUDED</div>
            <div>
                <ul>
                    <li>Bridge Loans</li>
                    <li>Commercial Mortgages</li>
                </ul>
            </div>
        </div>
    },
    {
        question: 'What exclusive privileges and rewards will I receive as a Power Agent',
        answer: <div>
            Funding your deals with Ginkgo has many advantages. Besides consistent bank-level service, you get a
            $100 gift card on your very first funded deal!
            That's our thanks to you for allowing us to show what we can do.
            <br /><br />
            <div className='row'>
                <div>
                    <div className='fontSizeM'>Power Agent</div>
                    <b>Minimum 3 deals plus</b>
                    <div>350,000 points: iPad or iWatch or Airpods Pro</div>
                    <div>and so many other options!</div>
                    <div>Benefits:</div>
                    <ul>
                        <li>designated underwriter</li>
                        <li>
                            Renewal trailer fee program
                            <i>
                                <p>
                                    <b> Renewal trailer fee program:</b>
                                    <div>
                                        <ul>
                                            <li>
                                                *The renewal trailer fee applies to renewals in the next calendar year if Power Agent status is achieved in the current year.
                                            </li>
                                            <li>
                                                *The renewal trailer fees equal to 20% of the renewal fees Ginkgo collected.
                                            </li>
                                            <li>
                                                *The amount funded by the lending partners are excluded from the calculation of trailer fee payments. Certain products are not qualified for the renewal trailer fee program.
                                            </li>
                                        </ul>
                                    </div>
                                </p>
                            </i>
                        </li>
                    </ul>
                </div>
                <div className='paddingLeftL'>
                    <div className='fontSizeM'>Elite Agent</div>
                    <b>Minimum 3 deals plus 700,000 points </b>
                    <div>Reward: All-inclusive vacation</div>
                    <div>Benefits:</div>
                    <ul>
                        <li>Designated underwriter</li>
                        <li>Renewal trailer fee program</li>
                        <li>
                            Buy down program
                            <i>
                                <p>
                                    <b>Buy Down Program:</b>
                                    <div>
                                        <ul>
                                            <li>
                                                Effective May 1, 2019
                                            </li>
                                            <li>
                                                Elite Agents can buy down the rate, buy down the fee, or cash for: 0.25% (25bps) on First Mortgages	OR 1% on Second Mortgages
                                        </li>
                                            <li>
                                                This begins <b>after</b> the Elite Agent status is achieved each calendar year. There is no carry forward for the next year.
                                        </li>
                                        </ul>
                                    </div>
                                </p>
                            </i>
                        </li>
                    </ul>
                </div>
            </div>
            <h4>Client Retention Benefits</h4>
            We do not take your clients. Our policy is to send clients back to their originating broker. You will receive complimentary automated SMS reminders for you AND your clients to help them stay on track!
            <br /><br />
            An SMS reminder will be sent to you and your clients at these times:
            <br /><br />
            <ul>
                <li>When there are 90 days left before your client's mortgage matures.</li>
                <li>Before each monthly payment day, to help clients improve their credit score.</li>
            </ul>
        </div>
    },
]


const QuestionsSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className='QuestionsSection'>
            <div className='pagePadding paddingBottomXL'>
                {
                    questionsAndAnswers.map(({ question, answer }, i) => <QuestionAndAnswer
                        question={question}
                        answer={answer}
                        noBorder={i === questionsAndAnswers.length - 1}
                    />)
                }
                <div className={isMobile ? '' : 'paddingBottomXL'}></div>
            </div>
            <div className={`blueBox withBackground ${isMobile ? 'paddingLeftM paddingRightM paddingTopL paddingBottomL' : 'paddingXL'} lightText centerText`}>
                <OnScrollAnimatior animation='fadeIn' preAnimationStyles={{ opacity: 0 }}>
                    <H2 className='paddingBottomM paddingTopM'>What Are You Waiting For?</H2>
                    <Button to='/submitadeal'>SEND IN YOUR DEAL</Button>
                    <div className='paddingTopM fontSizeXS'>
                        or
                    <Link to='contact'>
                            <b className='paddingRightXS paddingLeftXS'>Contact Us</b>
                        </Link>
                        for more information
                </div>
                </OnScrollAnimatior>
            </div>
        </div>
    )
}
export default pure(QuestionsSection)