import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import TeamMember from '../TeamMember/TeamMember'
import './DirectorsSection.css'
import { terence, yvonne, william, alexandra, henry } from '../MembersSection/members'
import elaine from './Elaine.png'
import peter from './peter.png'
import ronald from './Ronald.png'
import Henry from './Henry.png'

const directors = [
    {
        image: Henry,
        name: 'HENRY TSE',
        title: 'Chairman of the Board',
        education: 'CPA CA MBA CFP AMP CHS CLU',
        bio: <span>
            Henry obtained his MBA degree from McMaster University in 1989. He articled with Ernest & Young Chartered Accountants and was promoted to audit manager before earning his CA designation in 1990.
                <br /><br />
            As a licensed real estate sales representative, mortgage and insurance broker, Henry has over 15 years of experience in private mortgages.He also has extensive experience in preparing companies to become publicly traded, and was the CFO of three publicly traded companies listed on the TSX Venture Exchange.
            </span>
    },
    {
        image: ronald,
        name: 'RONALD LEE',
        title: 'Board Member',
        education: 'MBA AMP',
        bio: <span>
            Ronald has been working in the financial services industry since 1989, and is the owner of iBrokerPower Capital Inc, a Mortgage Centre Canada franchise. With his vast experience and many awards.
                <br /><br />
            He is a trusted advisor for the public, appearing often on local television and radio to inform on financial matters. As a specialist in residential, commercial mortgages and business loans, has helped many Canadians obtain financing for both their homes and business.
            </span>
    },
    {
        image: peter,
        name: 'PETER WONG',
        title: 'Board Member',
        education: 'P.Eng., MBA, MSC',
        bio: 'Peter has a Bachelor of Applied Science in Engineering from the University of Toronto, a Master of Science in Global Supply Chain Management, and a Master of Business Administration from the Kelley School of Business at Indiana University. With over 30 years of hands-on management experience, he recently returned to Chubb Fire & Security as their Head of Supply Chain and Logistics after spending 20 years as executive management in their Canadian product division from 1989 to 2010. Prior to returning to Chubb, Peter spent 7 years as Vice President of Operations at Technical Standards and Safety Authority (TSSA), an Ontario Government Regulator delivering public safety services. His responsibilities included providing strategic direction and ensuring operational effectiveness in TSSA\'s seven program areas. In addition, Peter also oversaw the organization\'s licensing, registration, and certification functions.'
    },
    {
        image: elaine,
        name: 'ELAINE MAK',
        title: 'Board Member',
        education: 'BA',
        bio: 'Elaine graduated with a Bachelor of Economics from McMaster University in 2007. She has completed the Exempt Market Dealer education requirement and has worked with various financial institutions to manage investors’ wealth and risk profile. Elaine is currently a licensed mortgage broker with Financial Services Commission of Ontario and also a licensed insurance agent in Ontario. With over 10 years of experience in the mortgage and personal finance industry, her knowledge in multiple financial disciplines has helped hundreds of her clients achieved financial success.'
    },

]



const DirectorsSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className='DirectorsSection pagePadding lightishBackground fadeIn'>
            <div className={isMobile ? 'paddingTopXL' : 'paddingXL'}>
                <div className={isMobile ? '' : 'paddingRightL paddingLeftL'}>
                    <H1 className='centerText paddingBottomXL'>
                        Board of Directors
                    </H1>
                    {
                        directors.map((director, i) => <div key={i}>
                            <TeamMember
                                image={director.image}
                                name={director.name}
                                title={director.title}
                                education={director.education}
                                bio={director.bio}
                                big />
                            <div className={isMobile ? '' : 'paddingL'}></div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}

export default pure(DirectorsSection)