import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import {
    Home,
    AboutUs,
    NewsAndEvents,
    Products,
    LoyaltyPrograms,
    Contact,
    SubmitADeal,
    BlogFeed,
    BlogPost,
    OurTeam,
    Appraisers,
    LendingArea,
    FAQ
} from "../Pages";

import {
    InvestorHome,
    InvestorInvestmentHub,
    InvestorHowToInvest,
    InvestorFAQ,
    InvestorWhatIsMIC,
    InvestorInvestmentStrategy,
    InvestorInvestmentProducts,
    InvestorNewsAndEvents,
    InvestorEventsAndSeminars,
    InvestorGallery,
    InvestorAnalystRating,
    InvestorLendingMap,
    InvestorHistoricalReturn,
    InvestorLoanPortfolio,
    InvestorFundPortfolio,
    InvestorOurPerformance,
    InvestorAboutUs,
    InvestorOurStory,
    InvestorTeam,
    InvestorWhyUs
} from "../Pages";

import { PrimaryNavigation, Footer } from "../Molecules";

const Router = () => (
    <BrowserRouter className="Router">
        <PrimaryNavigation />
        <Route path="/" exact component={Home} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/newsandevents" component={NewsAndEvents} />
        <Route path="/products" component={Products} />
        <Route path="/loyaltyprograms" component={LoyaltyPrograms} />
        <Route path="/submitadeal" component={SubmitADeal} />
        <Route path="/contact" component={Contact} />
        <Route path="/blogfeed" component={BlogFeed} />
        <Route path="/team" component={OurTeam} />
        <Route path="/appraisers" component={Appraisers} />
        <Route path="/lendingarea" component={LendingArea} />
        <Route path="/faq" component={FAQ} />

        <Route path="/blogpost/:id" component={BlogPost} />

        <Route path="/investor/" exact component={InvestorHome} />

        <Route path="/investor/investment-products" component={InvestorInvestmentProducts} />

        <Route path="/investor/investment-hub" component={InvestorInvestmentHub} />
        <Route path="/investor/how-to-invest" component={InvestorHowToInvest} />
        <Route path="/investor/faq" component={InvestorFAQ} />
        <Route path="/investor/what-is-mic" component={InvestorWhatIsMIC} />
        <Route path="/investor/investment-strategy" component={InvestorInvestmentStrategy} />

        <Route path="/investor/news-and-events" component={InvestorNewsAndEvents} />
        <Route path="/investor/events" component={InvestorEventsAndSeminars} />
        <Route path="/investor/gallery" component={InvestorGallery} />

        <Route path="/investor/analyst-rating" component={InvestorAnalystRating} />
        <Route path="/investor/lending-map" component={InvestorLendingMap} />
        <Route path="/investor/historical-return" component={InvestorHistoricalReturn} />
        <Route path="/investor/loan-portfolio" component={InvestorLoanPortfolio} />
        <Route path="/investor/fund-portfolio" component={InvestorFundPortfolio} />
        <Route path="/investor/our-performance" component={InvestorOurPerformance} />

        <Route path="/investor/about-us" component={InvestorAboutUs} />
        <Route path="/investor/our-story" component={InvestorOurStory} />
        <Route path="/investor/team" component={InvestorTeam} />
        <Route path="/investor/why-us" component={InvestorWhyUs} />
        <Footer />
    </BrowserRouter>
);


export default Router;
