import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../../Atoms";
import image from "../default-icon.png";
import "./SingleCard.css";

const defaultValues = {
    image,
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const SingleCard = props => (
    <div className="SingleCard">
        <div className="row">
            <img className="" src={props.image || image} alt="" />
        </div>
        <div className="textWrap">
            <div className="innerContent">
                <H2 className="fontSizeS lineHeightM fontWeightNormal">{props.title || defaultValues.title}</H2>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
