import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../../../../../Atoms'
import image from './image.png'
import './EventCard.css'
import { LaptopWindows, TitleOutlined } from '@material-ui/icons'

/* const eventContent = ({
    event: {
    Title: title,
    Imagepath: image,
    DateandTime: date,
    Address: address,
    Text: text,
    Text2: text2,
    Text3: text3,
    Text4: text4,
    Text5: text5,
    Label: label,
    Label2: label2,
    Label3: label3,
    onClick: onClick,
    onClick2: onClick2,
    onClick3: onClick3
    }
}
) => ( {text}
) */
/* ) => ( {title}, {image}, {date}, {address}, {text}

);
 */
    const EventCard = ({event}) => {
        let ev =event.onClick
        let ev2 =event.onClick
        let ev3 =event.onClick

     const   onClickF = ()=> {window.open(ev)}
     const   onClickF2 = ()=> {window.open(ev2)}
     const   onClickF3 = ()=> {window.open(ev3)}
    const isMobile = window.innerWidth < 800
    // const {event: {image=image , title, date, time, address, text, text2, text3, text4, text5, label,label2, label3, onClick, onClick2, onClick3}} = props;
    return (
        <div className='EventCard row roundedBig'>
            <div className='imageWrap'>
                <img className={isMobile ? 'roundedTopBig' : 'roundedLeftBig'} src={event.ImagePath} alt='' />
                
            </div>
            <div className={`textWrap darkerBackground lightText leftText ${isMobile ? 'roundedBottomBig paddingM fontSizeS' : 'roundedRightBig paddingL fontSizeXS'}`}>
                <H2>
                    {event.Title}
                </H2>
                <div className={isMobile ? 'paddingTopS' : 'paddingTopM'}>
                    {event.DateandTime}
                </div>
                <div className={isMobile ? 'paddingTopS' : 'paddingTopM'}>
                    {event.Time}
                </div>
                <div className={isMobile ? 'paddingTopS' : 'paddingTopM'}>
                    {event.Address}
                </div>
                <div className={isMobile ? 'paddingTopS paddingBottomM' : 'paddingTopM paddingBottomL'}>
                    {event.Text}
                    {event.Text2 && <br></br>}
                    {event.Text2 && <br></br>}
                    {event.Text2 && event.Text2}                  
                    {event.Text3 && <br></br>}
                    {event.Text3 && <br></br>}
                    {event.Text3 && event.Text3}                  
                    {event.Text4 && <br></br>}
                    {event.Text4 && event.Text4}                  
                    {event.Text5 && <br></br>}
                    {event.Text5 && <br></br>}
                    {event.Text5 && event.Text5}                  
                </div>
                <div>
                   <Button onClick={onClickF}>{event.Label}</Button>
                   {event.Label2 && <Button onClick={onClickF2}>{event.Label2}</Button>}
                   {event.Label3 && <Button onClick={onClickF3}>{event.label3}</Button>}
               </div>

           </div>
            
        </div>
    )
}

export default pure(EventCard)