import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>Analyst Rating</span>,
        text: (
            <span>
                Get a deep analysis of Ginkgo's business model and profitability, written by a third party independent
                research group.
            </span>
        ),
        link: "/investor/analyst-rating"
    },
    {
        image: Image2,
        title: <span>Loan Portfolio</span>,
        text: (
            <span>
                Your investment is diversified into a pool of mortgages to reduce your risk. Take a look and see how
                your funds are distributed across Canada.
            </span>
        ),
        link: "/investor/loan-portfolio"
    },
    {
        image: Image3,
        title: <span>Fund Portfolio</span>,
        text: (
            <span>
                Our performance at a glance - monthly performance report to show you how your investment is utilized.
            </span>
        ),
        link: "/investor/fund-portfolio"
    },
    {
        image: Image4,
        title: <span>Historical Returns</span>,
        text: <span>Since 2011, we have provided high, stable returns to our investors.</span>,
        link: "/investor/historical-return"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="paddingRightML ZeroRightPaddingOnMobile">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={5}>
                    <H1 className="fontSizeLL">The Ginkgo Report</H1>
                </Grid>
                <Grid item xs={5}>
                    <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                        With our commitment to quality, Ginkgo is one of the top-rated veterans in the private lending
                        industry. Take a look at how we do it.
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container space={3} justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
