import React, { PureComponent } from 'react'
import { Header, BodySection } from './components'
import { ContactForm, DownloadAppSection } from '../../../../Molecules'
import './InvestorOurStory.css'
import { VideoSection } from '../../../AboutUs/components'

class InvestorOurStory extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='InvestorOurStory lineHeightM'>
    {/*            <Header />   */}
                <VideoSection/>
                <BodySection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        )
    }
}

export default InvestorOurStory