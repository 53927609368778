import React from 'react'
import { pure } from 'recompose'
import { H2 } from '../../../../Atoms'
import { Carusel, BlogPostCard } from '../../../../Molecules'
import './OtherPosts.css'

const OtherPosts = props => (
    <div className='OtherPosts lightishBackground pagePadding paddingTopXL paddingBottomL'>
        <H2 className='centerText paddingBottomL'>Other Posts</H2>
        <Carusel items={2} nav>
            <BlogPostCard buttonBackgroundClassName='ctaBackground'/>
            <BlogPostCard buttonBackgroundClassName='ctaBackground' />
            <BlogPostCard buttonBackgroundClassName='ctaBackground' />
            <BlogPostCard buttonBackgroundClassName='ctaBackground' />
            <BlogPostCard buttonBackgroundClassName='ctaBackground' />
            <BlogPostCard buttonBackgroundClassName='ctaBackground' />
        </Carusel>
    </div>
)

export default pure(OtherPosts)