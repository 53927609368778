import React, { PureComponent } from "react";
import { Header, FAQSection, Body } from "./components";
import { ContactForm, DownloadAppSection } from "../../../../Molecules";

class FAQ extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="FAQ">
                <Header />
                <Body />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default FAQ;
