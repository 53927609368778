import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>Loan-to-Values</span>,
        text: (
            <span>
                We lend a maximum 80% of the current value of the property, in which the minimum value must be $250,000
                or more.
            </span>
        ),
        link: "/investor/analyst-rating"
    },
    {
        image: Image2,
        title: <span>Demography</span>,
        text: (
            <span>
                We accept properties in municipalities with populations of 50,000 or more. Primary regions include the
                Greater Toronto Area, Vancouver, Winnipeg, Calgary, and Edmonton.
            </span>
        ),
        link: "/investor/analyst-rating"
    },
    {
        image: Image3,
        title: <span>Documentation</span>,
        text: (
            <span>
                We require standard mortgage documents, credit report, appraisal, and 6 months bank statements. Article
                of incorporation, or letter of employment and 2 paystubs may also be requested.
            </span>
        ),
        link: "/investor/fund-portfolio"
    },
    {
        image: Image4,
        title: <span>Understanding Our Borrowers</span>,
        text: (
            <span>
                There are many reasons people borrow from Ginkgo. Learn how we vet our end users, and how they benefit
                from Ginkgo products.
            </span>
        ),
        link: "/investor/analyst-rating"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="paddingRightL GridSection">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={6}>
                    <H1 className="fontSizeLL">How We Underwrite Mortgages</H1>
                </Grid>
                <Grid item xs={5}>
                    <div className="paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                        Our team of dedicated Solution Advisors work with brokers across Canada to source quality
                        properties and mortgagees. Each mortgage must pass all of the following criteria in order to be
                        part of our loan portfolio.
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
