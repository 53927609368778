import React from 'react'
import { pure } from 'recompose'
import ReactPlayer from 'react-player'
import group from '././group.png'
import './VideoSection.css'
import { Carusel, OnScrollAnimatior }  from '../../../../Molecules'  
import { H1, Button } from '../../../../Atoms'

const VideoSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className='VideoSection'>
        <Carusel nav
        >
            
            <div className='caruselItem withBackground slideOne'>
            </div>

            <div className='caruselItem withBackground slideTwo '>
            </div>

            <div className='caruselItem withBackground slideThree'>
            </div>

            <div className='caruselItem withBackground slideFour'>
            </div>
        </Carusel> 
                  <OnScrollAnimatior animation='fadeIn' preAnimationStyles={{ opacity: 0 }}>
                        <div className='bold fontSizeM centerText paddingTopL'>
                            No special process, registration, or additional fees required
                        </div>
                        <div className={`centerText ${isMobile ? 'paddingTopS fontSizeS' : 'paddingTopL fontSizeXS'}`}>
                            Thank you for a great vacation! We’re glad to be a part of the Ginkgo Team. Special thanks to Steve and the whole team. You’re great!
                            Looking forward to doing more business with Ginkgo. GINKGO ROCKS!”
                        </div>
                        <div className={`bold centerText ${isMobile ? 'fontSizeS paddingBottomM' : 'fontSizeXS'}`}>
                            - Ali Reza, Mortgage Alliance
                        </div>
                    </OnScrollAnimatior>
        </div>
           )
}

export default pure(VideoSection)