import React from 'react'
import { pure } from 'recompose'
import { H2 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { SingleListItem } from './componets'
import './ChecklistSection.css'

const items = [
    {
        title: 'General Requirements:',
        text: <ul>
            <li>
                Population 50,000 and up
            </li>
            <li>
                Minimum value of property: $250,000
            <br />(Exceptions can be made)
            </li>
        </ul>
    },
    {
        title: 'Documents for Submission:',
        text: <ul>
            <li>
                Mortgage application form(Filogix preferred)
            </li>
            <li>
                Credit report
            </li>
        </ul>
    },
    {
        title: 'Required Documents after Approval:',
        text: <ul>
            <li>
                Standard mortgage supporting documents
            </li>
            <li>
                Appraisal from a Ginkgo MIC approved appraiser
                www.ginkgomic.com/broker/appraisers.html
            </li>
            <li>
                Confirmation of cashflow:
                <ul>
                    <li>6 months bank statement if mortgage is over $50,000</li>
                    <li>OR - Latter of employment and 2 current paystubs</li>
                </ul>
            </li>
            <li>
                Business for self/self employed confirmation
                <ul>
                    <li>6 months bank statement(most recent)</li>
                    <li>Article of incorporation / business license</li>
                </ul>
            </li>

        </ul>
    },
]

const ChecklistSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className={`ChecklistSection ${isMobile ? 'paddingBottomL' : 'paddingBottomXL'} pagePadding paddingTopXL blackishText`}>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <H2 className='centerText'>Underwriting Guidelines</H2>
            </OnScrollAnimatior>
            {
                items.map(({ title, text }, i) =>
                    <SingleListItem
                        title={title}
                        text={text}
                        noBorder={i === items.length - 1}
                    />
                )
            }
        </div>
    )
}
export default pure(ChecklistSection)