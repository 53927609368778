import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";
import SingleCard from "./SingleCard/SingleCard";
import { articles, freshContents } from "./BodySectionConstant";
import Grid from "@material-ui/core/Grid";
const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    const ArticleCards = contentArray =>
        contentArray.map(({ date, author, title, text, image, buttonTitle }, i) => (
            <Grid item xs={4}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard
                        date={date}
                        author={author}
                        title={title}
                        text={text}
                        buttonTitle={buttonTitle}
                        image={image}
                    />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXXL BodySection">
            <div>
                <H1 className="fontSizeLL centerText">Articles</H1>
                <Grid className="paddingTopXL" container direction="row">
                    {ArticleCards(articles)}
                </Grid>
            </div>
            <div className="paddingTopX paddingBottomXL">
                <H1 className="fontSizeLL paddingTopXXL centerText">Fresh Content</H1>
                <Grid className="paddingTopXL" container direction="row">
                    {ArticleCards(freshContents)}
                </Grid>
            </div>
        </div>
    );
};

export default pure(BodySection);
