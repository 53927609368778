import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText centerText withBackground">
        <H1 className="fontSizeLL">Why Us</H1>
        <div className="paddingBottomL fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
            <p>Quality. Transparency. Trust.</p>
            These core values are at the heart of our company culture and every decision we make. We know trust is
            earned, not given, so we work hard to prove we are a name you can trust.
        </div>
    </div>
);

export default pure(Header);
