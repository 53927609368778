import React, { PureComponent } from 'react'
import { MainSection } from './components'
import { ContactForm, StayConnected } from '../../Molecules'
import './NewsAndEvents.css'
import axios from 'axios'
import { serURL } from '../../API/url'

class NewsAndEvents extends PureComponent {
    constructor(props) {  
        super(props)
          this.state = {
              returnEvents : []
            }
      }


readSPEvents = async() => {
  
    const getEvents = await axios.get(serURL+"/events"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
        this.setState({returnEvents: getEvents.data.filter(event => event.Display === true).sort((a,b) => a.Order0 - b.Order0)})
 }



    componentDidMount() {
        this.readSPEvents()
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='NewsAndEvents lightishBackground'>
                <MainSection events={this.state.returnEvents}/>
                <StayConnected buttonBackgroundClassName='ctaBackground' />
                <ContactForm />
            </div>
        )
    }
}

export default NewsAndEvents