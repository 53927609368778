import React, { PureComponent } from "react";
import { BodySection } from "./components";
import { ContactForm, DownloadAppSection, ReadyToInvestSection } from "../../../../Molecules";

class InvestorTeam extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="InvestorTeam">
                <BodySection />
                <ReadyToInvestSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default InvestorTeam;
