import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../../Atoms";
import "./SingleCard.css";

import Grid from "@material-ui/core/Grid";

import image1 from "../image1.png";

const defaultValues = {
    image1,
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};
const isMobile = window.innerWidth < 850;

const SingleCard = props => (
    <div className="SingleCard paddingBottomXL">
        {isMobile || props.isImageOnLeft ? (
            <Grid container direction="row" justify="center">
                <Grid item>
                    <H1 className={`fontSizeLL paddingBottomM leftText}`}>{props.title || defaultValues.title}</H1>
                    <img className="smallImage" src={props.image || image1} alt="" />
                </Grid>
                <Grid item>
                    <div className="contentSection leftContentSection fontSizeM fontWeightNormal paddingTop100">
                        {props.text || defaultValues.text}
                    </div>
                </Grid>
            </Grid>
        ) : (
            <Grid container direction="row" justify="center">
                <Grid item>
                    <div className="contentSection rightContentSection fontSizeM fontWeightNormal paddingTop100">
                        {props.text || defaultValues.text}
                    </div>
                </Grid>
                <Grid item>
                    <H1 className={`fontSizeLL paddingBottomM rightText}`}>{props.title || defaultValues.title}</H1>
                    <img className="smallImage" src={props.image || image1} alt="" />
                </Grid>
            </Grid>
        )}
    </div>
);

export default pure(SingleCard);
