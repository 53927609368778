import React from 'react'
import { pure } from 'recompose'
import { MyGoogleMap } from './components'

import './MyMap.css'

// AIzaSyAVcOxEHDf9bPhNNtd3meX3Xy3vZ_om2y0

const MyMap = (props) => (
    <div className='MyMap'>
        <MyGoogleMap
            {...props}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAab4uqq_8x3EMsuhxGa2DOKkruFLsnq4M&v=3.exp"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    </div>
)

export default pure(MyMap)