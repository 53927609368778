const initialState = {
  userType: 'broker'
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'SET_USER_TYPE':
      return { ...state, userType: payload }
    default:
      return state
  }
}