import React from "react";

import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import image4 from "./image4.png";
import image5 from "./image5.png";
export const topCarouselCards = [
    {
        title: <span>Why is investing in a MIC is safer than other investment opportunities? </span>,
        text: (
            <span>
                Owning shares in a mortgage investment corporation enables you to invest in a company which manages a
                diversified and secured pool of mortgages. This spreads your risk amongst many existing properties,
                unlike purchasing a single piece of real estate which also requires much higher capital. Since the
                shares are secured against physical properties, it will also hold its value, whereas paper stocks have
                no intrinsic value.
            </span>
        ),
        buttonTitle: <span>See our Performance Portfolio</span>,
        image: image5,
        link: "/investor/our-performance"
    },
    {
        title: <span>How do investors make money?</span>,
        text: (
            <span>
                In today's market, first mortgage interest rates often range from 3-5%, while second mortgage interest
                rates can range from 12-16%. Since we specialize in higher rate second mortgages, we can provide our
                investors with higher returns.
                <br />
                <br /> As a flow-through vehicle, profits generated by MICs must be distributed to its shareholders
                according to their proportional interest.
            </span>
        ),
        buttonTitle: <span>See our Performance Portfolio</span>,
        image: image4,
        link: "/investor/our-performance"
    },

    {
        title: <span>How does Ginkgo MIC make money?</span>,
        text: (
            <span>
                Our business model is very similar to banks - we invest in (lend out) mortgages and collect monthly
                interest to pay our investors. However, Ginkgo MIC is different from banks in that we mainly lend out
                second mortgages; therefore, we can charge a higher interest rate and in turn give investors a higher
                dividend. On average, our mortgage rates range from 12% to 16% and allow investors to earn 8-9% in
                dividends.
            </span>
        ),
        buttonTitle: <span>See our Performance Portfolio</span>,
        image: image3,
        link: "/investor/our-performance"
    },
    {
        title: <span>Who borrows from Ginkgo?</span>,
        text: (
            <span>
                We fix financial problems <br />
                <br /> Our mortgage products are designed to be short term, helping borrowers improve their credit until
                they can qualify with institutional lenders again.
            </span>
        ),
        image: image2,
        buttonTitle: <span>See our Performance Portfolio</span>,
        link: "/investor/our-performance"
    },
    {
        title: <span>What is secured in a MIC</span>,
        text: (
            <span>
                Ginkgo typically invests mainly in first mortgages on residential properties. Every investment is based
                on a thorough investigation of the property, which includes, of course, a certified appraisal. A loan
                normally does not exceed 80% of the current value of the property to ensure that the initial investment
                will not diminish in the face of a drop in property price or other negative economic factors.
            </span>
        ),
        image: image1,
        buttonTitle: <span>See our Performance Portfolio</span>,
        link: "/investor/our-performance"
    }
];

export const bulletPoints = [
    "Licensed with FSCO",
    "Grow your income tax free!",
    "Qualified under Income Tax Act",
    "RDSP, RRSP, RRIF, TFSA, RESP eligible",
    "Tax-free growth and reinvestment until withdrawn"
];
