import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import './SubmitDealSection.css'

const SubmitDealSection = props => {

    const isMobile = window.innerWidth < 800
    return (
    <div className='SubmitDealSection darkBackground lightText paddingXL centerText'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <H2 className='paddingBottomM'>
                Have a deal that needs financing?
            <br /> Let us take a look!
        </H2>
            <Button
                to='/submitadeal'
                backgroundClassName='primaryStrongBackground'
            >
                Submit a deal
        </Button>
        </OnScrollAnimatior>
    </div>
    )
}

export default pure(SubmitDealSection)