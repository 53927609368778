import React from "react";
import { pure } from "recompose";
import "./BulletCard.css";
import checkIcon from "./check-icon.png";

import Grid from "@material-ui/core/Grid";
const defaultValues = {
    text: "Bank-level Service"
};

const BulletCard = props => (
    <div className="BulletCard">
        <Grid container>
            <Grid item xs={2}>
                <img className="CheckIconImage paddingRightM" src={checkIcon} alt="" />
            </Grid>
            <Grid item xs>
                <div className="fontSizeS lineHeightM fontWeightNormal paddingRightM">
                    {props.text || defaultValues.text}
                </div>
            </Grid>
        </Grid>
    </div>
);

export default pure(BulletCard);
