import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";

import { pure } from "recompose";
import { H2, Button } from "../../../../../../Atoms";
import "./UnbeatableGrowthSection.css";

import Grid from "@material-ui/core/Grid";

const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
    {
        markerOffset: -15,
        name: "Buenos Aires",
        coordinates: [-58.3816, -34.6037]
    },
    { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
    { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
    { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489] },
    { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711] },
    { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807] },
    { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
    { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
    { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
    { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
    { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
    { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] }
];

const UnbeatableGrowthSection = props => {
    return (
        <div className="UnbeatableGrowthSection pagePadding-Investor">
            <Grid container justify="center" spacing={1} direction="row">
                <Grid item xs={8}>
                    <H2 className="fontSize28">Unbeatable growth</H2>
                    <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
                        Want a detailed look of how each region is comprised, and how they form Ginkgo's loan portfolio?
                        Take a look!
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="buttonRow">
                        <Button
                            onClick={() => {
                                window.open("/investor/loan-portfolio");
                            }}
                            className="leftButton"
                        >
                            SEE REPORT
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div>
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map(geo => (
                                <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />
                            ))
                        }
                    </Geographies>
                    {markers.map(({ name, coordinates, markerOffset }) => (
                        <Marker key={name} coordinates={coordinates}>
                            <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
                            <text
                                textAnchor="middle"
                                y={markerOffset}
                                style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                            >
                                {name}
                            </text>
                        </Marker>
                    ))}
                </ComposableMap>
            </div>
        </div>
    );
};

export default pure(UnbeatableGrowthSection);
