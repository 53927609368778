import React, { PureComponent } from 'react'
import { pure } from 'recompose'
import { closeWhite } from '../../../../../../Assets'
import './AttachmentField.css'

class AttachmentField extends PureComponent {

    state = {
        files: []
    }

    onFileChange(e) {
        const uploadedFile = e.target.files[0]
        if (!uploadedFile)
            return
        const files = [...this.state.files]
        files.push(e.target.files[0])
        this.setState({ files })
        this.props.onChange(files)
    }

    removeFile(i){
        const files = [...this.state.files]
        files.splice(i, 1)
        this.setState({ files })
        this.props.onChange(files)
    }

    render() {
        const { props, state } = this
        return (
            <div className='AttachmentField'>
                <div className='row'>
                    <div className='label paddingRightS bold fontSizeXS'>
                        {props.label || 'Label'}
                    </div>
                    <div className='textBox fontSizeS blackishText withBackground'>
                    </div>
                    <input
                        onChange={this.onFileChange.bind(this)}
                        className='file'
                        type='file'

                    />

                </div>
                <div className='attachments'>
                    {
                        state.files.map((file, i) => (
                            <div className='fileName fontSizeXS'>
                                {file.name}
                                <img onClick={() => this.removeFile(i)} src={closeWhite} alt='' />
                            </div>
                        ))
                    }
                </div>

            </div>

        )
    }
}

export default pure(AttachmentField)