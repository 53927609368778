import { createStore } from 'redux'
import { combineReducers } from 'redux'
import appReducer from './appReducer'
import userReducer from './userReducer'


// Define the Reducers that will always be present in the application
const staticReducers = {
    app: appReducer,
    user: userReducer
}

function createReducer(asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    })
}

// Configure the store
const store = createStore(
    createReducer(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {}

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
}

export default store