import React from "react";
import Image1 from "./image1.png";
import Image2 from "./image2.png";
import Image3 from "./image3.png";
export const articles = [
    {
        date: <span>22 Oct 2018</span>,
        author: <span>Jack Morgan</span>,
        title: <span>Impact Of Extrinsic Motivation On Intrinsic Motivation</span>,
        text: (
            <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
            </span>
        ),
        buttonTitle: <span>READ ALL</span>
    },
    {
        date: <span>22 Oct 2018</span>,
        author: <span>Jack Morgan</span>,
        title: <span>Impact Of Extrinsic Motivation On Intrinsic Motivation</span>,
        text: (
            <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
            </span>
        ),
        buttonTitle: <span>READ ALL</span>
    },
    {
        date: <span>22 Oct 2018</span>,
        author: <span>Jack Morgan</span>,
        title: <span>Impact Of Extrinsic Motivation On Intrinsic Motivation</span>,
        text: (
            <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
            </span>
        ),
        buttonTitle: <span>READ ALL</span>
    }
];

export const freshContents = [
    {
        date: <span>22 Oct 2018</span>,
        title: <span>Impact Of Extrinsic Motivation On Intrinsic Motivation</span>,
        buttonTitle: <span>READ MORE</span>,
        image: Image1
    },
    {
        date: <span>22 Oct 2018</span>,
        title: <span>Success Steps For Your Personal Or Business Life</span>,
        buttonTitle: <span>READ MORE</span>,
        image: Image2
    },
    {
        date: <span>22 Oct 2018</span>,
        title: <span>Hypnosis 12 Steps To Acquire Mind Power</span>,
        buttonTitle: <span>READ MORE</span>,
        image: Image3
    }
];
