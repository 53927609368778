import React from "react";
import { pure } from "recompose";
import { H2, Button } from "../../../../../../Atoms";
import image from "../cardOne.png";
import "./HorizontalCard.css";

import Grid from "@material-ui/core/Grid";
const defaultValues = {
    image,
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const HorizontalCard = props => (
    <div className="HorizontalCard">
        <Grid container>
            <Grid item>
                <div className="row">
                    <img className="roundedLeftBig" src={props.image || image} alt="" />
                </div>
            </Grid>
            <Grid item>
                <div className="textWrap primaryBackground roundedRightBig">
                    <div className="innerContent paddingM">
                        <H2 className="fontSizeMM lineHeightM">{props.title || defaultValues.title}</H2>
                        <div className="paddingTopS fontSizeXS lineHeightM fontWeightNormal bodyText">
                            {props.text || defaultValues.text}
                        </div>

                        <Button
                            onClick={() => {
                                window.open(props.link);
                            }}
                        >
                            {props.buttonTitle}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
);

export default pure(HorizontalCard);
