import React, { PureComponent } from 'react'
import { Header, Facts, CardsSection, QuoteSection, Banner } from './components'
import { ContactForm } from '../../Molecules'
import './Home.css'

class Home extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='Home'>
                <Header />
                <Banner />
                <Facts />
                <CardsSection />
                <QuoteSection />
                <ContactForm />
            </div>
        )
    }
}

export default Home