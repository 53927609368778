import React, { PureComponent } from 'react'
import { Header, BodySection } from './components'
import { ContactForm, DownloadAppSection } from '../../../../Molecules'
import './InvestorAboutUs.css'
import { VideoSection } from '../../../AboutUs/components'
class AboutUs extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='InvestorAboutUs lineHeightM'>
                <Header />
                <BodySection />
    {/*            <VideoSection />    */}
                <DownloadAppSection />
                <ContactForm />
            </div>
        )
    }
}

export default AboutUs