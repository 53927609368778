import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header withBackground lightText center centerText fadeIn'>
        <div>
            <H1>Blog</H1>
            <div className='paddingTopM fontSizeS'>
                Stay up to date.
            </div>
        </div>
    </div>
)

export default pure(Header)
