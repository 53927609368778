import React, { Component } from 'react'
import { pure } from 'recompose'
import './TextField.css'

class TextField extends Component {
    state = {
        focus: null
    }

    render() {
        const { props } = this
        const { textarea, box, error } = props
        const onChange = props.onChange
            ? (e) => props.onChange(e.currentTarget.value)
            : function (e) { console.log(e.currentTarget.value) }
        return (
            <div className={` TextField fontSizeM ${textarea ? 'textarea' : ''} ${error ? 'error' : ''} ${box ? 'box' : ''}`}>
                <div className='textFieldLabel'>
                    {this.props.label}
                </div>
                {
                    textarea ?
                        <textarea className='fontSizeM borderDark roundedBig' onChange={onChange} />
                        : <input className='fontSizeM borderDark' onChange={onChange} />
                }
            </div>
        )
    }
}

export default pure(TextField)