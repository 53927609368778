import React from 'react'
import { pure } from 'recompose'
import { MortgageCard } from './components'
import './MortgageSection.css'

const MortgageSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className='MortgageSection pagePadding paddingTopXL paddingBottomXL'>
            <div className={isMobile ? '' : 'row paddingBottomL'}>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
            </div>
            <div className={isMobile ? '' : 'row paddingBottomL'}>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
                <div className={`rowItem center ${isMobile ? 'paddingBottomL' : 'paddingRightM paddingLeftM'}`}>
                    <MortgageCard />
                </div>
            </div>
        </div>
    )
}
export default pure(MortgageSection)