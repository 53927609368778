import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.jpg";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import henry from "./henry.jpg";
import image4 from "./agm21.jpg";
import cruise from "./2021Cruise.png";
import promotion from "./2022Promotion.png";
import AGM from "./2022AGMPressRelease.png"
import RedCross from "./2022RedCross.png"
import special from "./Special.png"
const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    return (
         <div className="pagePadding-Investor paddingTopSL paddingBottomXL BodySection">
{/*               <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText" >2022 Ginkgo MIC Annual General Meeting</H2>
                          <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                              <p>We extend a warm invitation to you to attend our Annual General Meeting. Join our live Zoom session and take a look back at the past year’s fund performance, highlights and achievements, as well to discuss our market forecast and future developments planned for the year ahead as Ginkgo enters into its next decade.
Friends and family are welcome to register and attend.</p>
                          </div>
                          <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                           <div className="whiteBackground paddingL centerText">                             
                            <div className="fontSizeM fontWeightBold">LIVE ZOOM WEBINAR</div>
                                <div className="fontSizeXS fontWeightBold">Mandarin</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Friday, February 4th 2022 2PM EST / 11AM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZYpd--orDMuEtImhn3257cUguZeDKI0sPRX"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>

                                   <div className="fontSizeXS fontWeightBold">English</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Friday, February 4th 2022 5PM EST / 2PM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZMsde-prz4qG9eH6UzMuK30m0Z3byGRo58u"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>
                                <div className="fontSizeXS fontWeightBold">Cantonese</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Saturday, February 5th 2021 2PM EST / 11AM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZIsdumqpjosH9xQS1cu9VUzm7DFXLBeuBu7"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>
                                   </div>
                          </div>

                        </div>
                  </Grid>
              </Grid>
    */}    

<div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividends Announcement: Commitment to Shareholder Value and Trust</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>April 17, 2024 - Ginkgo Mortgage Investment Corporation ("Ginkgo MIC") is pleased to announce that its Board of Directors has approved a special dividend of 2.5 cents per share (0.25%) on all Class A Preferred Shares for eligible shareholders on record as of December 31, 2023</p>
                                         <p>
                                            <a target="_blank" href="https://finance.yahoo.com/news/special-dividends-announcement-commitment-shareholder-130000418.html"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">MORE DETAILS</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


<div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">AGM Press Release</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo Mortgage Investment Corporation's AGM Highlights Stellar 2023 Performance and Growth Initiatives</p>                                    
                                    <p>
                                            <a target="_blank" href="https://finance.yahoo.com/news/ginkgo-mortgage-investment-corporations-agm-161000857.html"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">Read More</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


       <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Announcement </H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo Mortgage Investment Corporation ("Ginkgo") has reached a remarkable milestone by surpassing $100,000,000 in total investor equity!</p>
                                         <p>
                                         This achievement is a testament to the unwavering trust and support our incredible investors have placed in us. It's a reflection of our dedication and hard work to deliver outstanding returns for our valued stakeholders. We don't just raise capital; we build futures with the trust of our investors.
                                        </p>
                                        <p>
                                        We're immensely grateful for your trust in our vision, and we can't wait to embark on the exciting journey that lies ahead. Thank you for being a part of our success story!
has context menu
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement </H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo Mortgage Investment Corporation ("Ginkgo") is delighted to announce that its Board of Directors has declared another special dividend of 2.5 cents per share (0.25%) for all Class “A” participating preferred shareholders on record as of March 31, 2023. This special dividend will be payable on September 30, 2023.</p>
                                         <p>
                                            <a target="_blank" href="https://www.accesswire.com/761614/ginkgo-mic-announces-special-dividend-and-increased-annual-dividend-rate-targets"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">MORE DETAILS</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Ginkgo MIC Education Webinar Series Episode #1</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo Mortgage Investment Corporation recently held our first episode in our new educational series titled "The Risks and Rewards of Mortgage Investment"  We talk about current market conditions, the risks involved with investing in real estate and how to properly seek out sound investments based on your risk tolerance.  Hosted by our very own, CEO Mr. Henry Tse!</p>
                                         <p>
                                            <a target="_blank" href="https://bit.ly/489Hk8p"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">Video Link</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>



{/*         <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo Mortgage Investment Corporation ("Ginkgo") is pleased to announce that its Board of Directors declared a special dividend of 2.5 cents per share (0.25%) payable on June 30, 2023, to all Class "A" participating preference shareholders of record as of December 31, 2022. Additionally, effective May 31, 2023, the annual dividend rate target was increased for Class "A" Participating Preference Shares, Series 1 ("Series 1 Preference Shares") from 9.25% to 9.75% and for Class "A" Participating Preference Shares, Series 2 from 8.50% to 9.00%.</p>
                                    <p>
                                            <a target="_blank" href="https://bit.ly/3p8f1pa"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">More Details</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
        <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Ginkgo MIC's Record Profits Result in Higher Dividend Targets</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo MIC ("Ginkgo") is pleased to announce that its Board of Directors has declared a revision to its annual dividend targets in response to Bank of Canada's December 7th interest rate hike. Effective immediately, the annual dividend rate for Series 1 Preferred Shares will be increased from 8.75% to 9.25%; and from 8.25% to 8.5% for Series 2 Preferred Shares.</p>
                                    <p>
                                            <a target="_blank" href="https://yhoo.it/3X1ezo2"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">More Details</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


{/*                <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo MIC is pleased to announce that its Board of Directors has declared a <b>special dividend</b> on all qualified Series 1 and Series 2 Class A Preferred Shares. <b>The $0.05 (0.5%) per share</b> dividend is payable to shareholders who were on record as of December 31, 2021 and remained on record until August 31, 2022. Special dividends are a one-time distribution and will be paid out by October 31, 2022. </p>
                                    <p>This special dividend is made possible as a result of strong management control, excellent agent partnership and support, along with a dynamic Ginkgo team, all integral for a record year of corporate profits amid interest rate hikes and worldwide economic downturn. According to the CEO, Henry Tse, “Our team continues to look for ways to reducing our risk exposure and to improve rate of returns. This record year of profits is a validation that we are heading in the right directions.” “Nowadays, inflation is on everyone’s mind. It is important for Ginkgo to keep up our dividends with the interest rate hikes so that our valued investors will not see diminishing returns to their investments. We will continue to evaluate dividend rates as the Bank of Canada continues to hike interest rates to fight inflation,” said director Peter Wong.
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Press Release: 2022 July Market Update</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>GINKGO MIC REMAINS STRONG AMID THE CURRENT ECONOMIC ENVIRONMENT
On July 21 and 23, 2022, Ginkgo MIC Market Analyst Meeting was held and hosted by CEO, Henry Tse, in three separate sessions - English, Mandarin and Cantonese. It was held through virtual format to reach Ginkgo’s investors across Canada. During this meeting, Henry provided insights to Ginkgo’s investors regarding the many changes to the current market and the company’s financial performance, projections and strategies.</p>
                                    <p>
                                            <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EVTc_LwyaHBLjfSqIQ6qW7sB9TprWA1vq5IIsB4g3ldUgQ?e=dc1yjr"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">Read More</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


{/*               <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo MIC is pleased to announce that its Board of Directors has declared a special dividend on all qualified Series 1 and Series 2 Class A Preferred Shares. The $0.50 (0.5%) per share dividend is payable to shareholders who were on record as of December 31, 2021 and remained on record until August 31, 2022. Special dividends are a one-time distribution and will be paid out by October 31, 2022. </p>
                                    <p>
                                            <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EVTc_LwyaHBLjfSqIQ6qW7sB9TprWA1vq5IIsB4g3ldUgQ?e=dc1yjr"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">Read More</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

 */}
 {/*              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={special} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Shareholder Announcement - Revised Dividend Targets</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>In response to the recent interest rate hike by the Bank of Canada, we are pleased to announce that the Board of Directors has revised its annual dividend targets.</p>
                                        <p>Effective immediately, the annual dividend rate for Series 1 Preferred Shares will be increased from 8% to 8.25%; and from 7% to 7.75% for Series 2 Preferred Shares. </p>
                                       <p>This increase builds upon the success related to critical planning and purposeful actions taken over the past years. Ginkgo MIC will continue to strategize to provide our valued investors with the confidence of stable returns despite the volatility experienced in the investment market. </p>  
                                        <p>A kind reminder that you may view your portfolio details by signing into the Investor Portal accessible through the top of the Ginkgo MIC website – Investors tab.</p>
                                        <p>If you are interested in adding to your investment, making a referral or have any questions related to your investment, you may contact Yvonne, Investors Relations at yvonne@ginkgomic.com or +1-416-990-5567.</p>    
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
        <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={AGM} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Annual General Meeting Press Release | GINKGO MIC REMAINS STRONG AMID THE PANDEMIC</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>The 2021-2022 Annual General Meeting of Ginkgo Mortgage Investment Corporation (AGM) was held on February 4 & 5, 2022 hosted by CEO, Henry Tse, in three separate sessions - English, Mandarin and Cantonese. It was held through virtual format to protect the health and safety of all parties. During this annual meeting, Henry covered details and insights to Ginkgo’s investors regarding the company’s financial performance, projections and future strategies.</p>
                                         <p>
                                            <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/ERTvxbS35YVIiuqN8B_dUTEBEJaODeCAFQuzHX-0lfIipw"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">Read More</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

     {/*         <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo3} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Financial Webinar for Working Adults</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                              <p>Hosted by Henry Tse, expert in financial consulting and future planning, this is a unique opportunity for those in the younger working class to gain first-hand insight into the benefits of investing young and how it contributes to building wealth over time.</p>
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                <div className="fontSizeM fontWeightBold">LIVE ZOOM WEBINAR</div>
                                <div className="fontSizeXS fontWeightBold">English</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Thursday, June 17th 2021 6PM EST / 3PM PT</p>
                                         <p>
                                            <button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER</button>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
    */}

         <div className="paddingTopXL">
        
        <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                <Grid item xs>
                    <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                </Grid>
                <Grid item xs>
                    <div className="paddingLL marginOnMobile">
                        <H2 className="lightText centerText" >News</H2>
                        <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                            <p>Read the latest news happening at Ginkgo</p>
                        </div>
                        <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                            <div className="whiteBackground paddingL centerText">
                                <div className="fontSizeM fontWeightBold">Read our 2022 Fundamental Analysis Report</div>
                                <div className="fontSizeS fontWeightNormal paddingTopS">
                                    <p>Gingko MIC receives high investment rating yet again! Read the 2022 report below and explore current market forecasts, anticipated dividend projections and more great information on what makes Ginkgo a great investment today.</p>
                                    <p>
                                        <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EdM0BQlWBadHhxgsk2TOYFcBaPV4D7PuJZ7Y6YurM-TiyQ"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">READ MORE</button></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            
            {/*
            <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={henry} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText" >Investment Seminar</H2>
                          <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                              <p>Learn how to invest in mortgages worry-free, and earn a passive income with an annual 7-8% rate of return. Join our free seminar:</p>
                          </div>
                          <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                            <div className="whiteBackground paddingL centerText">                             
                               <div className="fontSizeM fontWeightBold">2020 Seminar</div>                                
                                    <p>
                                        <a href="https://discover.ginkgomic.com/seminar/"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">FREE SIGN UP</button></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                  </Grid>
              </Grid>
            </div>
            
            <div className="paddingTopXL">
                <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                    <Grid item xs>
                        <img className="mainIamgeSection roundedBig" src={image2} alt="" />
                    </Grid>
                    <Grid item xs>
                        <div className="paddingLL marginOnMobile">
                            <H2 className="lightText">Seminars</H2>
                            <div className="fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL fullWidth">
                                Don't know where to start investing? Not to worry. We believe that financial freedom is
                                achieved through financial literacy.
                            </div>
                            <div className="paddingBottomML paddingRightXL paddingTopL fullWidth">
                                <div className="whiteBackground paddingL centerText ">
                                    <div className="fontSizeM fontWeightBold">How to get 8% return</div>
                                    <div className="fontSizeS fontWeightNormal paddingTopS">
                                        Learn how you can achieve high returns in real estate without intensive capital.
                                    </div>
                                </div>
                                <div className="paddingTopL fullWidth">
                                    <div className="whiteBackground paddingL centerText ">
                                        <div className="fontSizeM fontWeightBold">Raptors Game 3 Party</div>
                                        <div className="fontSizeS fontWeightNormal paddingTopS">
                                            Learn how you can achieve high returns in real estate without intensive
                                            capital.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            */}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    );
};

export default pure(BodySection);
