import React from "react";

import Elane from "./images/elane.png";
import ElaneSmall from "./images/elaneSmall.png";

import Yvonne from "./images/yvonne.png";
import YvonneSmall from "./images/yvonneSmall.png";

import Henry from "./images/henryBig.png";
import HenrySmall from "./images/henrySmall.png";

// import Ronald from "./images/ronaldBig.png";
// import RonaldSmall from "./images/ronaldSmall.png";

import Peter from "./images/peterBig.png";
import PeterSmall from "./images/peterSmall.png";
export const teams = [
    {
        name: <span>Elaine Mak</span>,
        title: <span>Board of Directors</span>,
        text: (
            <span>
                Elaine graduated with a Bachelor of Economics from McMaster University in 2007. She has completed the
                Exempt Market Dealer education requirement and has worked with various financial institutions to manage
                investors' wealth and risk profile. Elaine is currently a licensed mortgage broker with Financial
                Services Commission of Ontario and also a licensed insurance agent in Ontario. With over 10 years of
                experience in the mortgage and personal finance industry, her knowledge in multiple financial
                disciplines has helped hundreds of her clients achieve financial success.
                <br />
                <br /> If you are a current investor and have questions about your investment, Elaine will be able to
                help.
            </span>
        ),
        bigImage: Elane,
        smallImage: ElaneSmall
    },
    {
        name: <span>YVONNE LEUNG</span>,
        title: <span>Investor Relations</span>,
        text: (
            <span>
                Yvonne has been with Ginkgo MIC since our early years. With over 20 years in customer service and event
                planning, she has been at the forefront in creating engaging educational seminars to ensure we deliver
                on our promise: to be transparent and to keep our investors well.
                <br />
                <br /> If you are new to Ginkgo and have questions, or would like a personal introduction, Yvonne would
                be happy to tell you more. Give her a call below:
                <br />
                <br /> (647) 952-8117 | yvonne@ginkgomic.com
            </span>
        ),
        bigImage: Yvonne,
        smallImage: YvonneSmall
    },
    {
        name: <span>HENRY TSE</span>,
        title: (
            <span>
                Chairman of the Board
                <br /> CPA CA MBA CFP AMP CHS CLU
            </span>
        ),
        text: (
            <span>
                Henry obtained his MBA degree from McMaster University in 1989. He articled with Ernest & Young
                Chartered Accountants and was promoted to audit manager before earning his CA designation in 1990.{" "}
                <br />
                <br />
                As a licensed real estate sales representative, mortgage and insurance broker, Henry has over 15 years
                of experience in private mortgages. He also has extensive experience in preparing companies to become
                publicly traded, and was the CFO of three publicly traded companies listed on the TSX Venture Exchange.
            </span>
        ),
        bigImage: Henry,
        smallImage: HenrySmall
    },
    /*
    {
        name: <span>RONALD LEE</span>,
        title: (
            <span>
                Board Member
                <br /> CPA CA MBA CFP AMP CHS CLU
            </span>
        ),
        text: (
            <span>
                Ronald has been working in the financial services industry since 1989, and is the owner of iBrokerPower
                Capital Inc, a Mortgage Centre Canada franchise. With his vast experience and many awards.
                <br />
                <br /> He is a trusted advisor for the public, appearing often on local television and radio to inform
                on financial matters. As a specialist in residential, commercial mortgages and business loans, has
                helped many Canadians obtain financing for both their homes and business.
            </span>
        ),
        bigImage: Ronald,
        smallImage: RonaldSmall
    },
    */
    {
        name: <span>PETER WONG</span>,
        title: (
            <span>
                Board Member
                <br /> P.Eng., MBA, MSC
            </span>
        ),
        text: (
            <span>
                Peter has a Bachelor of Applied Science in Engineering from the University of Toronto, a Master of
                Science in Global Supply Chain Management and a MAster of Business Administration from the Kelly School
                of Business at Indiana University. With over 25 years of hands on management experience, he currently
                serves as the Vice President of Operations at Technical Standards and Safety Authority (TSSA), an
                Ontario Government Regulator delivering public safety services. His responsibilities include providing
                strategic direction and ensuring operational effectiveness in TSSA's seven program areas. In addition,
                Peter also oversees the organization's licensing, registration and industry compliance. Prior to joining
                TSSA in June 2013, he held senior management positions at Chubb and Verex, subsidiaries of a large
                publicly traded conglomerate company, United Technologies.
            </span>
        ),
        bigImage: Peter,
        smallImage: PeterSmall
    }
];
