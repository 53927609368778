import React from 'react'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { H1 } from '../../../../Atoms'
import hero from './hero.png'
import './Header.css'

const Header = props => (
    <div className='Header'>
        <div className='pagePadding paddingTopL'>
            <Link to='/blogfeed' className='row'>
                <div className='arrowLeft'></div>
                <div className='bold'>
                    Back to Blog
                </div>
            </Link>
            <div className='paddingTopL paddingBottomXL'>
                <H1>
                    2019 Elite Agent Trip
                </H1>
            </div>
        </div>
        <div className='heroWrap'>
            <img src={hero} alt='' />
        </div>
    </div>
)

export default pure(Header)