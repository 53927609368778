
import React, { PureComponent } from 'react'
import { Header, ProgressSection, ChecklistSection, SubmitionSection } from './components'
import { ContactForm } from '../../Molecules'
import './SubmitADeal.css'
import AppraisalSection from "./components/AppraisalSection/AppraisalSection";

class SubmitADeal extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='SubmitADeal'>
                <Header />
                <ProgressSection />
                <SubmitionSection />
                <ChecklistSection />
                <AppraisalSection />
                <ContactForm />
            </div>
        )
    }
}

export default SubmitADeal