import React from "react";
import { H1, Button } from "../../../../../Atoms";
import { pure } from "recompose";
import "./Header.css";
import image2 from "./background2.png";
import { Facts } from '../../components';
import Grid from "@material-ui/core/Grid";
import { Carusel } from "../../../../../Molecules";
import { backgroundImg } from "./background.png";
class Popup extends React.Component {
    render() {
        return (
            <div className="popup">
                <div className="popup_inner">
                    <h1>COMING SOON</h1>
                    <button onClick={this.props.closePopup}>close</button>
                </div>
            </div>
        );
    }
}
class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            showPopup: false
        };
    }
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }
    render() {
        const isMobile = window.innerWidth < 800;
        const isTablet = window.innerWidth < 1280;
        return (
            <div className="Header withBackground">
                 <div className="headerSection withBackground slideOne slideInLeft"> 
{/*                 <div className="headerInvestor slideOne">
                <img className="slideTwoImage" src={backgroundImg} alt="Ginkgo MIC" />
                    <a href="//investor/how-to-invest"><Button>START NOW!</Button></a>
 */}{/*                     <div className="slideOneInnerContent">
                         <H1 className="fadeIn fontSizeXXL">
                            Welcome to
                            <br /> stable investment
                            <br /> with Ginkgo MIC
                        </H1>
                         <div className="buttonRow">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/investor/how-to-invest";
                                }}
                            >
                                START NOW!
                            </Button>
                        </div>
                    </div>
 */}                </div>
                <Facts />

                <div className="headerSection withBackground slideTwo">
                    <Grid container justify="center">
                        <Grid item>
                            <img className="slideTwoImage" src={image2} alt="" />
                        </Grid>
                        <Grid item>
                            <div className="paddingLeftXXL noLeftMarginOnMobile">
                                <div className="slideTwoInnerContent">
                                    <H1 className="fadeIn fontSizeLL">How we work</H1>
                                    <div className="slideTwoBody fontSizeS paddingTopM fadeIn paddingBottomM">
                                        As a mortgage investment corporation, we help two types of clients. We help
                                        investors grow their wealth securely through a diversified portfolio, and
                                        provide mortgages to borrowers looking to achieve their financial goals.
                                    </div>
                                    <div className="buttonRow slideTwoButtonRow">
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = "/investor/how-to-invest";
                                            }}
                                        >
                                            START NOW!
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {this.state.showPopup ? <Popup text="Close Me" closePopup={this.togglePopup.bind(this)} /> : null}
            </div>
        );
    }
}

export default pure(Header);
