import React, { PureComponent } from 'react'
import { pure } from 'recompose'
import { OnScrollAnimatior } from "../../../../Molecules";
import Grid from "@material-ui/core/Grid";
import { H1 } from "../../../../Atoms"
import coulson from './coulson-and-company-appraisals-ltd - resized96pxheight.png'
import bonafide from './Bonafide Appraisal - resized96pxheight.png'
import everest from './Everest Appraisal - resized96pxheight.png'
import frjordan from './FRJordan Horiz 4C-01 - resized96pxheight.png'
import gifford from './Gifford - resized96pxheight.png'
import hvi from './HVI-Appraisal-Logo---Green-Lettering - resized96pxheight.png'
import lamrotta from './LAMIROTTA-Logo - resized96pxheight.png'
import logo24 from './24 LOGO - resized96pxheight.png'

const AppraisalRqm = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div>
{/*             <div className='greenText centerText'>
                <p>
                <br/><br/>
                </p>
                Appraisal requirements have changed to adhere to COVID-19 safety measures
                <br/><br/>
                <div className='greenText centerText bold'> 
                    <a href="https://bit.ly/39U80uB">View modified appraisal requirements</a>
                </div>
            </div>	
 */}
            <div className="pagePadding-Investor">
                <OnScrollAnimatior animation="fadeInUp" isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    {/* <div className="line dividerBackground"></div> */}
                    <div className="paddingTopL paddingBottomL">
                        <Grid className="paddingTopL" container direction="row" justify="center" alignItems="center">
                            <Grid item direction="column">
                            <a href="http://coulsonandcompanyappraisal.ca/">
                                <img className="logoImage" style={{ marginBottom: '20px' }} src={coulson} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://bonafidevalue.com/">
                                <img className="logoImageOne" style={{ marginBottom: '20px' }} src={bonafide} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://everestappraisals.com/">
                                <img className="logoImageTwo" style={{ marginBottom: '20px' }} src={everest} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://www.frjordan.com/">
                                <img className="logoImageThree" style={{ marginBottom: '20px' }} src={frjordan} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://giffordappraisals.com/">
                                <img className="logoImageThree" style={{ marginBottom: '20px' }} src={gifford} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://hviorder.ca/">
                                <img className="logoImageThree" style={{ marginBottom: '20px' }} src={hvi} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://www.lamirottaco.com/">
                                <img className="logoImageThree" style={{ marginRight: '20px' }} src={lamrotta} alt="" />
                            </a>
                            </Grid>
                            <Grid item direction="column">
                            <a href="https://www.24appraisal.ca/index.html">
                                <img className="logoImageThree" style={{ marginLeft: '20px' }} src={logo24} alt="" />
                            </a>
                            </Grid>
                        </Grid>
                    </div>
                </OnScrollAnimatior>
            </div>
        </div>
    )
}
export default pure(AppraisalRqm);