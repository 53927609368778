import React, { PureComponent } from 'react'
import './QuestionAndAnswer.css'
import { OnScrollAnimatior } from '../../Molecules'

class QuestionAndAnswer extends PureComponent {

    state = { open: false }
    mainElemRef = React.createRef()

    onClick = function (event) {
        if (event.target.nodeName === 'I') {
            event.stopPropagation()
            return
        }
        this.setState({ open: !this.state.open })
    }.bind(this)

    componentDidUpdate(prevProps, prevState) {
        if (this.state.open && !prevState.open) {
            const { $ } = window
            if (!$) throw 'No $$$$$$'
            const { mainElemRef } = this
            const iElems = $(mainElemRef.current.getElementsByTagName('i'))
            const screenWidth = $('body').outerWidth()

            console.log({ screenWidth })

            iElems.each(function (i, iElem) {
                const elem = $(iElem)

                const elemOffsetLeft = elem.offset().left
                const elemOffsetRight = screenWidth - elem.offset().left - iElems.width()

                if (elemOffsetLeft > elemOffsetRight) {
                    elem.children().css({ left: 'unset', right: 20 })
                }

                const largerOffset = elemOffsetLeft > elemOffsetRight ? elemOffsetLeft : elemOffsetRight

                const isMobile = window.innerWidth < 800

                elem.children().css({ width: largerOffset - (isMobile ? 50 : 250) })


                console.log('mavo to left')
                const popups = iElems.children()
            })
        }

        return true
    }

    render() {
        const isMobile = window.innerWidth < 800
        return (
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div ref={this.mainElemRef} className={`QuestionAndAnswer lineHeightM borderPrimaryStrong paddingLeftL paddingRightL ${this.state.open ? 'open' : ''}`} onClick={this.onClick}>
                    <div className='head paddingTopL'>
                        <div className='icon'>
                        </div>
                        <div className='fontSizeM paddingLeftM'>
                            {this.props.question || 'Question???'}
                        </div>
                    </div>
                    <div className={`answer paddingTopM paddingBottomM fontSizeS ${isMobile ? '' : 'paddingLeftL'}`}>
                        <div className={isMobile ? '' : 'paddingLeftM'}>
                            {
                                this.props.answer
                                ||
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                            }
                        </div>
                    </div>
                </div>
            </OnScrollAnimatior>
        )
    }
}

export default QuestionAndAnswer