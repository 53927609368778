import React from "react";
import { pure } from "recompose";
import { H2, Button } from "../../../../../../../Atoms";
import "./SingleCard.css";

const defaultValues = {
    number: 1,
    title: "Bank-level Service",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
};

const SingleCard = props => (
    <div className={`SingleCard ${props.image ? "LongerHeight" : ""}`}>
        <div className="fadeInUp paddingS borderDark">
            {props.image ? <img className="mainImage roundedBig" src={props.image} alt="" /> : <div />}
            <div className={`${props.image ? "" : "innerContentWithBorder paddingM"}`}>
                <div className="fontSizeXS paddingTopM fontWeightNormal">
                    {props.date} {props.author ? <span>| By {props.author}</span> : <span />}
                </div>
                <H2 className="lineHeightM fontSizeMM paddingTopSM">{props.title || defaultValues.title}</H2>
                {props.text ? (
                    <div className="fontSizeXS lineHeightM fontWeightNormal paddingTopSM paddingRightL">
                        {props.text}
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className="paddingTopL">
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "http://www.ginkgomic.com/Investor/LoanPortfolio.aspx";
                    }}
                >
                    {props.buttonTitle}
                </Button>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
