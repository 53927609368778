import React, { PureComponent } from "react";
import { Header, ReportSection } from "./components";
import { ContactForm, DownloadAppSection } from "../../../../Molecules";

class InvestmentStrategy extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="InvestmentStrategy">
                <Header />
                <ReportSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default InvestmentStrategy;
