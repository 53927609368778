import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">News &amp; Events</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp marginOnMobile">
            <p>Stay up to date with your Ginkgo investment! Read our announcements and attend our seminars.</p>
        </div>
    </div>
);

export default pure(Header);
