import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header withBackground lightText centerText">
        <H1 className="fontSizeLL">How to Invest</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            Mortgage investment is a simpler and more affordable way to participate in one of the safest asset
            investments - real estate!
        </div>
    </div>
);

export default pure(Header);
