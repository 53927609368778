import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import './Header.css'

const Header = props => {
    const isMobile = window.innerWidth < 800
    return (
    <div className='Header withBackground lightText'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <H1>
                Our Team
            </H1>
        </OnScrollAnimatior>
        <OnScrollAnimatior animation='fadeInUp' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <div className='paddingTopL lineHeightM fontSizeS'>
                At Ginkgo MIC, we believe in providing more than just profitable financial products and services. We build relationships, and we grow together. With first-class quality customer service and expert knowledge, our team of qualified professionals is ready and dedicated to ensuring your complete satisfaction and financial growth.
        </div>
        </OnScrollAnimatior>
    </div>
)
}

export default pure(Header)