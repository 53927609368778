import React, { PureComponent } from "react";
import { BodySection } from "./components";
import { ContactForm, DownloadAppSection, ResourcesSection } from "../../../../Molecules";

class HistoricalReturn extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="HistoricalReturn">
                <BodySection />
                <ResourcesSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default HistoricalReturn;
