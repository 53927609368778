
import React, { PureComponent } from 'react'
import { Header, OfficesSection, MyMap } from './components'
import { ContactForm } from '../../Molecules'
import './Contact.css'

class Contact extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='Contact'>
                <Header />
                <ContactForm />
                <OfficesSection />
                <MyMap />
            </div>
        )
    }
}

export default Contact