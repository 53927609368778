import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">Ginkgo Investment Hub</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            The place to get all your investment questions answered
        </div>
    </div>
);

export default pure(Header);
