import React, { PureComponent } from 'react'
import { pure } from 'recompose'
import { H2, Button, AppLoader } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { baseURL } from '../../../../API'
import { HorizontalTextField, AttachmentField } from './components'
import axios from 'axios'
import './SubmitionSection.css'

class SubmitionSection extends PureComponent {

    state = {
        isLoading: false,
        didSubmit: false
    }

    name = ''
    email = ''
    phone = ''
    files = []

    async submit() {
        const { name, email, phone, files } = this

        const bodyFormData = new FormData();
        const file = files[0];

        bodyFormData.append('name', name);
        bodyFormData.append('email', email);
        bodyFormData.append('phone', phone);
        bodyFormData.append('file', file, file.name);

        this.setState({ isLoading: true })
        axios({
            method: 'post',
            url: `${baseURL}/api/submit-deal/deal`,
            data: bodyFormData,
            headers: {
                //'Content-Type': 'multipart/form-data', 
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Credentials': 'true' 
            }
        }).then((response) => {
            this.setState({ isLoading: false })
            this.setState({didSubmit: true })
        }, (error) => {
            this.setState({ isLoading: false })
            alert('Something went wrong, please try again later.');
        });
    }

    getTitle() {
        const { isLoading, didSubmit } = this.state


        if (isLoading)
            return 'Submitting Your Deal...'
        else if (didSubmit)
            return 'We will get back to your shortly after reviewing your deal'
        else
            return 'Submit a Deal'

    }

    render() {
        const isMobile = window.innerWidth < 800
        const { isLoading, didSubmit } = this.state
        return (
           <div className={`SubmitionSection darkBackground pagePadding paddingTopL paddingBottomL lightText `}>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <H2 className={`centerText ${isMobile ? 'paddingBottomM' : ''}`}>
                        {this.getTitle()}
                    </H2>
                    {
                        isLoading ?
                            <AppLoader />
                            : !didSubmit ? (
                                <div>
                                    <div className={isMobile ? '' : 'row paddingTopL paddingRightL'}>
                                        <HorizontalTextField onChange={(e) => this.name = e.target.value} label='Name' />
                                        <HorizontalTextField onChange={(e) => this.email = e.target.value} label='Email' />
                                    </div>
                                    <div className={isMobile ? '' : 'row paddingTopL paddingRightL'}>
                                        <HorizontalTextField onChange={(e) => this.phone = e.target.value} label='Phone' />
                                        <AttachmentField onChange={(argumentFiles) => this.files = argumentFiles} label='Attachment' />
                                    </div>
                                    <div className='paddingTopL center'>
                                        <Button onClick={this.submit.bind(this)} >Submit a deal</Button>
                                    </div>
                                </div>
                            )
                                : <div></div>
                    }
                </OnScrollAnimatior>
            </div>
        )
    }
}

export default pure(SubmitionSection)