import React from 'react'
import SingleCard from './SingleCard/SingleCard'
import { Carusel, OnScrollAnimatior } from '../../../../Molecules'
import { pure } from 'recompose'

import cardOne from './cardOne.png'
import cardTwo from './cardTwo.png'
import cardThree from './cardThree.png'


import './CardsSection.css'

const cards = [
    {
        image: cardOne,
        title: <span> Bank-level
                <br /> Service
            </span>,
        text: <span>
            <br />
            Brokers partnering with Ginkgo will not only enjoy the success of a completed deal, but the journey as well with:
            <ul>
                <li>24 Hour Turnaround</li>
                <li>3 Hour Response Time</li>
                <li>Up to 80% LTV</li>
                <li>No Hidden Fees</li>
            </ul>
        </span>
    },
    {
        image: cardTwo,
        title: <span> Sunshine
                    <br /> & Success!
                </span>,
        text: <span>
            <br />
        Our Elite Agents recently returned from our all-inclusive trip to Punta Cana where there was good food, great views, and amazing memories. Contact our BDMs to learn how easy it is to join us on our next trip!</span>
    },
    {
        image: cardThree,
        title: <span>

            High Returns
            <br />With Security
                </span>,
        text: <span>
            <br />
        As a lender in the real estate industry, our assets are secured in physical properties that will always have value. Therefore, unlike volatile paper assets and stock market options - we can give investors a steady yield of 8-9% worry-free.            </span>
    },
]


const CardsSection = props => {
    const isMobile = window.innerWidth < 800
    const Cards = () => cards.map(({ image, title, text }, i) => (
        <OnScrollAnimatior
    
            key={i}
            animation='fadeInUp'
            delay={i * 100} 
            preAnimationStyles={{ opacity: 0 }}
            isMobile = {isMobile}
            onSuccess={(scrollAnimatior) => console.log('Card Animating', scrollAnimatior)}
        >
            <SingleCard
                image={image}
                title={title}
                text={text}
            />
        </OnScrollAnimatior>
    ))


    return (
        <div className='CardsSection lightishBackground pagePadding'>
            {
                isMobile ? Cards() : <Carusel items={isMobile ? 1.15 : 3} darkNav disableLoop>
                    {Cards()}
                </Carusel>
            }
            <div className='filler'></div>
        </div>


    )
}

export default pure(CardsSection)