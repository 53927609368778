import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../Atoms'
import { OnScrollAnimatior } from '../../Molecules'
import './StayConnected.css'

const StayConnected = props => {

    const isMobile = window.innerWidth < 800
    return (
    <div className='lightText centerText StayConnected pagePadding'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <H2>
                Stay Connected
            </H2>
            <div className='fontSizeS paddingTopM paddingBottomM plainText'>
                Stay on top with the latest broker news from Ginkgo MIC - we send new promotions, Power Agent points statements, and invitations to our Lunch & Learns straight to your inbox!
            </div>
            <a target="_blank" href='https://bit.ly/3j0t6Ob'><Button backgroundClassName={props.buttonBackgroundClassName || 'darkishBackground'}>SUBSCRIBE</Button></a>
        </OnScrollAnimatior>
    </div>
    )
}

export default pure(StayConnected)