import React, { PureComponent } from 'react'
import { pure } from 'recompose'
import { h2 } from '../../../../Atoms'

const SPHeader = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className='blueText centerText bold'>
            <h2 className="blueText">Special Programs</h2>
            <p> Special circumstances call for unique solutions.
            <br/>Take a look at our special programs or limited time offers
            </p>
            <p>We will give you a tailored solution, give us a call at 416-901-5133 to discuss your deal!</p>
        </div>		
    )
}
export default pure(SPHeader);