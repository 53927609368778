import React from "react";
import { pure } from "recompose";
import { H2, Button } from "../../../../../../Atoms";
import image from "../cardOne.png";
import "./SingleCard.css";

const defaultValues = {
    image,
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const SingleCard = props => (
    <div className="SingleCard">
        <div className="row">
            <img className="roundedTopBig" src={props.image || image} alt="" />
        </div>
        <div className="textWrap primaryBackground roundedBottomBig">
            <div className="innerContent paddingM">
                <H2 className="fontSizeMM lineHeightM">{props.title || defaultValues.title}</H2>
                <div className="fontSizeXS lineHeightM bodyText fontWeightNormal">
                    {props.text || defaultValues.text}
                </div>

                <Button
                    onClick={() => {
                        window.open(props.link);
                    }}
                >
                    {props.buttonTitle}
                </Button>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
