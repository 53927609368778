import React from 'react'
import { pure } from 'recompose'
import { Button } from '../../../../../../Atoms'
import image from './image.png'
import './MortgageCard.css'

const MortgageCard = props => {
    return (
        <div className='MortgageCard'>
            <img src={image} alt='' />
            <div className='textWrap paddingBottomM blackishText'>
                <div className='bold paddingTopM paddingBottomS fontSizeS'>
                    Mortgage Title
            </div>
                <div className='fontSizeXS lineHeightM'>
                    <div>LTV: up to 65%</div>
                    <div>Rate: 7.49% to 8.99%</div>
                    <div>Term: Open after 3 months</div>
                    <div>Payment: insert-only</div>
                    <div>Fees: Starting from 1%</div>
                    <div className='paddingBottomM'>Not Beacon score driven</div>
                </div>

                <Button
                    backgroundClassName='darkBackground'
                />
            </div>
        </div>
    )
}

export default pure(MortgageCard)