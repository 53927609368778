import React, { PureComponent } from 'react'
import { H2 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { pure } from 'recompose'
import banner from './banner.png'
import './Banner.css'

class Banner extends PureComponent {  

render() {
//const Facts = props => {
    const isMobile = window.innerWidth < 800
    return (
                <div className='Banner container img'>
                        <img src={banner} alt='' />
                </div>
    )
}
}
export default pure(Banner)