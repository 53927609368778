import React, { PureComponent } from "react";
import { Header, GraphSection } from "./components";
import { ContactForm, DownloadAppSection, ResourcesSection } from "../../../../Molecules";

class FundPortfolio extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="FundPortfolio">
                <Header />
                <GraphSection />
                <ResourcesSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default FundPortfolio;
