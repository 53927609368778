import React, { PureComponent } from 'react'
import { } from '../../Assets'
import './Dropdown.css'

const options = [
    'Broker inquiry',
    'Investment inquiry',
    'Borrower inquiry',
    'General inquiry',
    'Discharge/Payment',
    'Renewal/Insurance'
]

class Dropdown extends PureComponent {


    state = {
        items: [
            { isActive: true, text: 'Broker inquiry', id: 1 },
            { isActive: false, text: 'Investment inquiry', id: 2 },
            { isActive: false, text: 'Borrower inquiry', id: 3 },
            { isActive: false, text: 'General inquiry', id: 4 },
            { isActive: false, text: 'Discharge/Payment', id: 5 },
            { isActive: false, text: 'Renewal/Insurance', id: 6 }
        ]
    }

    setActive = function (id) {
        this.props.onChange(id);
        
        const { items } = this.state
        items.forEach(item => {
            if (item.id === id)
                item.isActive = true
            else
                item.isActive = false
        })
        this.setState({ items: [...items] })
    }.bind(this)


    render() {

        const { setActive, state, props } = this
        const { items } = state
        const { error } = props

        const activeItem = items.filter(item => item.isActive)
        const unactiveItems = items.filter(item => !item.isActive)


        return (
            <div className={` Dropdown fontSizeM ${error ? 'error' : ''}`}>
                <div className='DropdownLabel target'>
                    {this.props.label}
                    <span className='arrow'></span>
                </div>
                <div className='value'>
                    {activeItem[0].text}
                </div>
                <div className='dropdownItems lightBackground fontSizeS roundedBottomBig'>
                    {
                        unactiveItems.map(item => (
                            <div
                                onClick={() => setActive(item.id)}
                                className='ddItem hoverPrimaryBackground'
                                key={item.id}
                            >
                                {item.text}
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default Dropdown