import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { EventCard } from './components'
import { event_11, event_10, event_1, event_9, event_3, event_4, event_5, event_6, event_7, event_8 } from './eventsImages'
import './MainSection.css'

/* const events = [
/*     {
        image: event_11,
        title: 'Fill a stable with us this holiday',
        text: 'This year, Ginkgo will continue its charity initiative by supporting World Vision in delivering self-sustaining resources to families in need. Starting November 1st, we will donate $100 for every mortgage deal funded.',
        label: 'Submit a deal now!',
        onClick() {
            window.open('https://ginkgomic.com/submitadeal/')
        },
        label2:'Donate separately',
        onClick2() {
            window.open('https://worldvisioncan.akaraisin.com/ui/gchelpfillastable/t/7a2d7698b0aa4756aa0dc8345fa5201c')
        }
     },
 */ 
 
    /*    {
        image: event_10,
        title: 'Celebrate Ginkgo\'s 10th Anniversary with us! Over $10,000 in cash and prizes!',
        text: 'We could not have gotten to where we are today without our many amazing broker partners originating Ginkgo mortgages. So to celebrate this big milestone we will be celebrating with our classic Ginkgo Bingo game night, but with even bigger prizes - $10,000 worth, such as a North American round-trip for 2! Invitations have been sent to all mortgage agents that have sent in at least one deal. Agents, please check your email for our invitation to RSVP. If you have not sent a deal to us before, now is a great time to do so!',
        date: 'Tuesday, August 10, 2021',
        time: '8PM-10PM EST',
        label: 'Send a deal now',
        onClick() {
            window.open('https://ginkgomic.com/submitadeal')
        }
    },
   {
        image: event_9,
        title: 'Introducing the NEW Ginkgo MIC Deal Tracker App',
        text: 'We have been working hard the past year to develop one of the first apps in the Canadian private lending industry! Download it into your phone today and manage your deals stress-free. Check your power agent points, contact your Solution Advisor and never miss a deal! *Note: Existing Ginkgo agents can begin using the app',
        label: 'Read more',
        onClick() {
            window.open('https://bit.ly/35N38rg')
        },
        label2:'App Store',
        onClick2() {
            window.open('https://apps.apple.com/us/app/ginkgo-mic-deal-tracker/id1556099489#?platform=iphone')
        },
        label3:'Play Store',
        onClick3() {
            window.open('https://play.google.com/store/apps/details?id=com.ginkgomic.ginkgo&hl=en')
        }
    },
    {
        image: event_8,
        title: 'Measures regarding COVID-19',
        text: 'Ginkgo MIC is fully operational and prepared to help during this time of uncertainty.',
        label: 'Read more',
        onClick() {
            window.open('https://bit.ly/34niHWi')
        }
    },
    {
        image: event_7,
        title: 'MPC Symposium, Toronto',
        date: 'April 15, 2021',
        address: 'Pearson Convention Center 2638 Steeles Avenue East Brampton, ON L6T 4L7',
    },
    {
        image: event_6,
        title: 'CMBA Symposium, Vaughan',
        date: 'February 25, 2021',
        time: '8:00am - 12:30pm',
        address: 'Universal Event Space 6250 Hwy 7, Vaughan, ON L4H 4G3',
    },
    {
        image: event_4,
        title: 'GOLF Tournament',
        date: 'Wednesday, October 7, 2020',
        time: '9:30am - 8:00pm',
        address: 'The Manor by Peter & Pauls Carrying Place Golf& Country Club',
        text: ''
    },
    
{
    image: event_12,
    title: 'Ginkgo Believes All Human Life is Precious and No Innocent Person Should Suffer. Ukraine Will Not Stand Alone',
    text: 'Ginkgo MIC is strongly against the unwarranted invasion and oppression set against the innocent lives of Ukraine. It is heartbreaking to see families, homes, lives and freedom affected, taken apart, and destroyed. Ginkgo MIC stands in solidarity with the people of Ukraine',
    text2: 'Ukraine continues to need our help and it is not too late. Canada matched Red Cross Ukraine donations up to $30 million, and we are adding our voice to the call for peace by doubling your donations, together supporting and making a bigger impact for those in need. From now until April 30, 2022, we will match every dollar donated through our fundraiser with Red Cross, up to $30,000, to respond to humanitarian needs and activities in aid of Ukraine and surrounding countries.   The donated money allows the reputable organization to quickly purchase supplies and relief items based on the specific needs of the people',
    text3: 'No donation is too small, every dollar, every effort counts',
    text4: 'It will take time to heal but let’s combine our efforts to help address this humanitarian crisis',
    text5: 'Our hearts and prayer are with the people of Ukraine, their families and loved ones',
    label: 'Support and Donate',
    onClick() {
        window.open('https://www.redcross.ca/UkraineCrisis/ginkgomic')
    }
},
*/
/* {
    image: event_11,
    title: 'Ginkgo MIC\'s Record Profits Result in Higher Dividend Targets',
    text: 'Ginkgo MIC ("Ginkgo") is pleased to announce that its Board of Directors has declared a revision to its annual dividend targets in response to Bank of Canada\'s December 7th interest rate hike. Effective immediately, the annual dividend rate for Series 1 Preferred Shares will be increased from 8.75% to 9.25%; and from 8.25% to 8.5% for Series 2 Preferred Shares.',
    label: 'More Details',
    onClick() {
        window.open('https://yhoo.it/3X1ezo2')
    },
},
    {
        image: event_1,
        title: 'MORTGAGE Workshops',
        date: 'January 17, 2023',
        address: '',
        text:'We are serious about your success! Our free, monthly webinar or in-person workshops will give you an update on product and promotions, how to best use them, and industry trend updates. We will often have industry speakers on the panel as well to provide even more educational value. Click below to see the next workshop or to book a free private session for your team.',
        label: 'Take a look',
        onClick() {
            window.open('https://go.pardot.com/l/361851/2019-10-08/6tqws')
        }
    },

    {
        image: 'https://ginkgowebsite.s3.us-east-2.amazonaws.com/event_9.png',
        title: 'Introducing the NEW Ginkgo MIC Deal Tracker App',
        text: 'We have been working hard the past year to develop one of the first apps in the Canadian private lending industry! Download it into your phone today and manage your deals stress-free. Check your power agent points, contact your Solution Advisor and never miss a deal! *Note: Existing Ginkgo agents can begin using the app',
        label: 'Read more',
        onClick() {
            window.open('https://bit.ly/35N38rg')
        },
        label2:'App Store',
        onClick2() {
            window.open('https://apps.apple.com/us/app/ginkgo-mic-deal-tracker/id1556099489#?platform=iphone')
        },
        label3:'Play Store',
        onClick3() {
            window.open('https://play.google.com/store/apps/details?id=com.ginkgomic.ginkgo&hl=en')
        }
    },


]
 */
const MainSection = ({events}) => {
    const isMobile = window.innerWidth < 800
    const spacingElem = <div className={isMobile ? 'paddingM' : 'paddingL'}></div>
    return (
        <div className='MainSection'>
            <div className={`pagePadding paddingTopXL paddingBottomXL ${!isMobile ? 'centerText' : ''}`}>
                <H1>
                    News and Events
                </H1>
                <div className={`${isMobile ? 'paddingBottomM centerText' : 'paddingBottomXL'}`}>
                    <div className='fadeInUp'>
                        <div className='fontSizeM paddingTopM'>
                            <b>
                                Discover how GINKGO can get your deal funded
                        </b>
                        </div>

                        <div className={`${isMobile ? 'fontSizeS' : 'fontSizeXS'}`}>
                            Knowledge is the path to success, which is why we host many educational <br /> workshops. See how a partnership with Ginkgo is part of a great business strategy!
                        </div>
                    </div>
                </div>
                {
                    events.map((event, i) => (
                        <OnScrollAnimatior
                            animation='fadeInUp'
                            key={i}
                            isMobile = {isMobile}
                            preAnimationStyles={{ opacity: 0 }}
                        >
                            <div >
                                <EventCard event={event} />
                                {spacingElem}
                            </div>
                        </OnScrollAnimatior>
                    ))
                },
            </div>
        </div>
    )
}
export default pure(MainSection)
