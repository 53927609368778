import React, { PureComponent } from "react";
import { Header, MapSection, UnbeatableGrowthSection } from "./components";
import { ContactForm } from "../../../../Molecules";
import "./LendingMap.css";

class LendingMap extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="LendingMap">
                <Header />
               <MapSection />   
                <ContactForm />
            </div>
        );
    }
}

export default LendingMap;
