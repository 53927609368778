import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className='Header lightText centerText paddingRightM paddingLeftM'>
            <H1 className='fadeIn'>Products Overview</H1>
            <div className={`paddingTopS plainText ${isMobile ? 'fontSizeS' : 'fontSizeXS'} fadeInUp`}>
                if you're looking for the best deal in mortrgage products, look no further!
                <br/>Check out the competitive rates we have for our products below
            </div>
        </div>
    )
}
export default pure(Header)