import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header withBackground lightText fadeIn' >
    </div>
)

export default pure(Header)