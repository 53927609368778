import React, { PureComponent } from 'react'
import './SearchBox.css'

class SearchBox extends PureComponent {
    render () {
        return (
            <input className='SearchBox fontSizeS' />
        )
    }
}

export default SearchBox