import React, { PureComponent } from 'react'
import { OnScrollAnimatior } from '../../../../Molecules'
import { H2, Button } from '../../../../Atoms'
import { Link } from 'react-router-dom'
import { pure } from 'recompose'
import moment from 'moment'
import './AgentTier.css'
import { URL } from 'url'
import poweragent from './PowerAgent.png'
import eliteagent from './EliteAgent.png'
import superelite from './SuperElite.png'

const plans = ['Power Agent', 'Elite Agent', 'Super Elite Agent']

class AgentTier extends PureComponent {

    state = {
        activeTab: 0
    }

    formatUrl(url){
        if (url.startsWith('http')){
            return url;
        }else{
            return "http://"+url;
        }
    }
    renderColumn(index, value){

        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);

        if (value.match(regex)) {
            return <a href={this.formatUrl(value)}><i className="fas fa-link"></i></a>;
        } else {
            return value;
        }
    }
    render() {
        const isMobile = window.innerWidth < 800
        return (
        <div className={`SpecialPromotion pagePadding paddingBottomXL ${!isMobile ? 'paddingTopM' : 'paddingTopS'}`}>
            <div className='tableWrap paddingTopL'>
                <div className='underLine primaryBackground'>
                </div>
                    <div className='thead'>
                        {plans.map((plan, i) => (
                            <div
                                className={`${i === this.state.activeTab ? 'primaryBackground lightText' : 'lightishBackground'} paddingS fontSizeM roundedTopBig center bold`}
                                onClick={() => this.setState({ activeTab: i })}
                                key={i}
                            >
                                {plan}
                            </div>
                        ))}
                    </div>
                    <div className={`condoRescue ${this.state.activeTab === 0 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={poweragent} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="greenText">Power Agent Tier</h2>
                            <h3 className="orangeText">3 Deals and 350,000+ Points</h3>
                            <h3>Your choice of prize from our extensive gift list!</h3>
                            <h3 className="greenText">20% Renewal trailer fee program*</h3>
                            <ul>
                                <li key="1">Must achieve Power Agent status this year ({moment().year()})</li>
                                <li key="2">If agents do not reach Power Agent status the following year (e.g. {moment().add(1, "years").year()}), the 20% renewal fee will not be applicable for {moment().add(2, "years").year()}</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`condoRescue ${this.state.activeTab === 1 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={eliteagent} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="greenText">Elite Agent Tier</h2>
                            <h3 className="orangeText">3 Deals and 700,000+ Points</h3>
                            When international travel criteria are finalized, we will resume our annual Elite Power Agent vacation. You can choose to climb aboard, or spoil yourself with a $2,000
                            <h3>All-inclusive Vacation Voucher</h3>
                            <h3 className="greenText">Buy down program!</h3>
                            Elite Agents can buy down the rate, buy down the fee, or cash for:
                            <ul>
                                <li key="1">0.25% (25bps) on First Mortgages</li>
                                <li key="2">1% on Second Mortgages</li>
                            </ul>
                            This begins after the Elite Agent status is achieved each calendar year. There is no carry forward for the next year.
                            <br></br><br></br>*only applies to 12 months term products
                        </div>
                    </div>
                    <div className={`condoRescue ${this.state.activeTab === 2 ? '' : 'hidden'}`}>
                        <div className='condoRescue-image'>
                            <img src={superelite} alt='' />
                        </div>
                        <div className='condoRescue-text'>
                            <h2 className="greenText">Super Elite Agent Tier</h2>
                            <h3 className="orangeText">3 Deals and 1.2 Million Points</h3>
                            <h3>Additional All-inclusive Vacation Voucher - bring your partner in crime!</h3>
                            <h3>Volume Bonus</h3>
                            Earn additional commission on your deals after reaching 1.5 million points<br></br>
                            *These bonuses will be paid by cheque to agent with T4A issued at the end of the calendar year.
                        <div className='tableWrap'> 
                            <table>
                            <tr>
                                    <td class="c1"><b>Points</b></td>
                                    <td class="c1"><b>Commision</b></td>
                                </tr>
                                <tr>
                                    <td class="c1"><b>1.5M -2M</b></td>
                                    <td class="c1"><b>$ 2000</b></td>
                                </tr>
                                <tr>
                                <td class="c1"><b>2M -2.5M</b></td>
                                    <td class="c1"><b>$ 4200</b></td>
                                </tr>
                                <tr>
                                <td class="c1"><b>2.5M -3M</b></td>
                                    <td class="c1"><b>$ 6600</b></td>
                                </tr>
                                <tr>
                                <td class="c1"><b>3M -3.5M</b></td>
                                    <td class="c1"><b>$ 10000</b></td>
                                </tr>
                                <tr>
                                <td class="c1"><b>3.5M -4M</b></td>
                                    <td class="c1"><b>$ 12000</b></td>
                                </tr>
                                <tr>
                                <td class="c1"><b>{'>'} 4M</b></td>
                                    <td class="c1"><b>$ 15000</b></td>
                                </tr>
                            </table> 
                        </div>
                    </div>
                    </div>
            </div>
        </div>
        )

    }
}

export default pure(AgentTier)