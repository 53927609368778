import React from 'react'
import { pure } from 'recompose'
import './SingleOffice.css'

const SingleOffice = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className={`SingleOffice row paddingTopL paddingBottomL ${props.withBottomBorder ? 'withBottomBorder' : ''}`}>
            <div className={`rowItem fontSizeL ${isMobile ? 'paddingBottomM' : 'centerText'}`}>
                <b>{props.office}</b>
            </div>
            <div className='rowItem'>
                <div className='paddingBottomM'>
                    {props.phone}
            </div>
                <div className='paddingBottomM'>
                    support@ginkgomic.com
            </div>
                <div>
                {props.address}
            </div>
            </div>
        </div>
    )
}

export default pure(SingleOffice)