import React from "react";
import { pure } from "recompose";
import { H2, H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import image2 from "./ginkgo-image.png";

import TimelineCard from "./TimelineCard/TimelineCard";
const timelineCards = [
    {
        number: 1,
        title: <span>Contact Us</span>,
        text: (
            <span>
                We will schedule you an appointment with an EMD, and tell you what documents and identification to bring
                to your appointment. You will need to attend in person (unless by exceptional circumstances)
            </span>
        )
    },
    {
        number: 2,
        title: <span>Meet Your EMD</span>,
        text: (
            <span>
                They will answer any questions you may have and ensure you are well-informed before you sign any
                documents. You will complete a Know Your Client form and Subscription Agreement.
            </span>
        )
    },
    {
        number: 3,
        title: <span>Let Your Dividends Roll In!</span>,
        text: (
            <span>
                Once your forms are processed, you will receive a welcome package about your investment. You will
                receive SMS notifications every time your dividends are deposited, and monthly portfolio report
                delivered to your email.
            </span>
        )
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    const TimelineCards = () =>
        timelineCards.map(({ number, title, text }, i) => (
            <Grid item className="paddingRightL zeroRightPaddingMobile">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <TimelineCard number={number} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="BodySection">
            <div className="paddingTopXXL paddingBottomXL">
                <div className="paddingBottomXL">
                    <H1 className="fontSizeLL centerText">How it works</H1>
                    <div className="fontSizeS lineHeightM fontWeightNormal InnerContent paddingTopM paddingBottomM">
                        Securities of a MIC are purchased through an Exempt Market Dealer (EMD) as required by the FSCO.
                        Here's how you can get started on growing your wealth:
                    </div>
                    <Grid container direction="row" justify="center" className="marginOnMobile">
                        {TimelineCards()}
                    </Grid>
                </div>

                <Grid container className="fadeInUp" direction="row" justify="center">
                    <Grid item>
                        <div className="timelineLeftSection paddingRightL marginOnMobile">
                            <div className="topSection">
                                <H1 className="fontSizeLL">Start Here</H1>
                                <H2 className="lineHeightM fontSizeMM">Your Journey Begins below</H2>
                                <div className="fontSizeS lineHeightM fontWeightNormal">
                                    We are happy to set you up with an Exempt Market Dealer and help you maximize your
                                    portfolio with Ginkgo. Call us at 416-901-5133 or investor@ginkgomic.com and you're
                                    good to go!
                                </div>
                                <div className="buttonRow">
                                    <Button
                                        onClick={() => {
                                            window.location.assign("mailto:investor@ginkgomic.com");
                                        }}
                                    >
                                        START NOW
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item justify="center">
                        <div className="paddingLeftL">
              {/*               <img src={image2} className="rightImage" alt="" /> */}
                            <H2 className="lineHeightM fontSizeMM centerText">Ready? Let’s go! </H2>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default pure(BodySection);
