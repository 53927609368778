import React, { PureComponent } from "react";
import { Header, BodySection } from "./components";
import { ContactForm, DownloadAppSection } from "../../../../Molecules";

class Gallery extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="InvestorGallery">
                <Header />
                <BodySection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default Gallery;
