import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { Header, PostBody, OtherPosts } from './components'
import {ContactForm, StayConnected} from '../../Molecules'
import './BlogPost.css'

class BlogPost extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { id } = this.props.match.params
        return (
            <div className='BlogPost'>
                <Header />
                <PostBody />
                <OtherPosts />
                <StayConnected buttonBackgroundClassName='ctaBackground'/>
                <ContactForm />
            </div>
        )
    }
}

export default withRouter(BlogPost)