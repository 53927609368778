import React from 'react'
import { pure } from 'recompose'
import './HorizontalTextField.css'

const HorizontalTextField = props => (
    <div className='HorizontalTextField row'>
        <div className='label paddingRightS bold fontSizeXS'>
            {props.label || 'Label'}
        </div>
        <input onChange={props.onChange || function () { }} className='fontSizeS blackishText' />
    </div>
)

export default pure(HorizontalTextField)