import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";

import SingleCard from "./SingleCard/SingleCard";
const aboutUsCards = [
    {
        image: image1,
        title: <span>Our Philosophy</span>,
        text: (
            <span>
                <b>Quality, transparency, and trust</b> - these are the core values that we know are mandatory for a
                great product, and a truly resilient business that will withstand the tests of time.
                <br />
                <br />
                We make sure to achieve our core values daily with the following framework of ethics, which we have
                displayed prominently across our office walls:
                <br />
                <br />
                - Safe
                <br />
                - Sound
                <br />
                - Secure
            </span>
        ),
        isImageOnLeft: true
    },
    {
        image: image2,
        title: <span>Our Commitment</span>,
        text: (
            <span>
                At Ginkgo, we strive to be the gold standard for alternative lending.
                <br />
                <br />
                You entrust us with your investment to grow your wealth or retirement fund, so we take our commitment
                very seriously. We won't hide behind vague reporting - trust is earned, and we have a solid business –
                we will make sure you receive regular detailed updates about your investment.
            </span>
        ),
        isImageOnLeft: false
    },
    {
        image: image3,
        title: <span>Our Philanthrophy</span>,
        text: (
            <span>
                At Ginkgo, we practice gratitude and recognize the importance of community when achieving success. We
                give back yearly by volunteering at Second Harvest, a Toronto non-profit organization committed to
                increasing accessibility to fresh food while reducing food waste. <br />
                <br />
                Our staff have worked at the Second Harvest warehouse as a way of team building, sorting fresh produce
                that would otherwise be in landfills simply for not meeting grocery store-standard aesthetics. <br />
                <br />
                As a result, we have contributed to providing over 16,500 meals and hundreds of pounds of fresh produce
                from going to waste since the start of our initiative in 2016.{" "}
            </span>
        ),
        isImageOnLeft: true
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;

    const AboutUsCards = () =>
        aboutUsCards.map(({ image, title, text, isImageOnLeft }, i) => (
            <OnScrollAnimatior
                key={i}
                animation="fadeInUp"
                delay={i * 100}
                preAnimationStyles={{ opacity: 0 }}
                isMobile={isMobile}
                onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
            >
                <SingleCard image={image} title={title} text={text} isImageOnLeft={isImageOnLeft} />
            </OnScrollAnimatior>
        ));

    return (
        <div className="BodySection pagePadding-Investor">
            <div
                className={`aboutUs fadeInUp primaryBackground ${
                    !isMobile ? "paddingTopXL paddingBottomXL roundedBig" : "paddingL"
                }`}
            >
                <div className={!isMobile ? "paddingRightXL paddingLeftXL" : ""}>
                    <H1 className={`fontSizeLL centerText ${!isMobile ? "paddingBottomM" : ""}`}>About Us</H1>
                    <div className="bodyText fontWeightNormal lineHeightM fontSizeM">
                        <p>
                            Ginkgo Mortgage Investment Corporation is an established, Canada-wide alternative lender
                            helping borrowers solve tough financial problems, while offering investors steady financial
                            growth through high-yielding mortgages.
                        </p>
                        <p>
                            Our vision is to be a prominent leader of the private mortgage industry by providing
                            comprehensive solutions to all stakeholders.{" "}
                        </p>
                    </div>
                </div>
            </div>

            <div className="paddingTopXXL">{AboutUsCards()}</div>
        </div>
    );
};
export default pure(BodySection);
