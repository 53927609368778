import React from 'react'
import { pure } from 'recompose'
import './colors.css'
import './main.css'
import './typography.css'
import './dimensions.css'
import './animations.css'

const WithStyles = props => (
    <div>
        {props.children}
    </div>
)

export default pure(WithStyles)