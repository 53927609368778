import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>Preferred Shares</span>,
        text: (
            <span>
                Returns at 9%
                <br /> An ideal investment for those who are
                <br /> looking to grow their wealth.
            </span>
        ),
        link: "https://bit.ly/34UawAO"
    },
/*     {
        image: Image1,
        title: <span>Bonds</span>,
        text: (
            <span>
                Returns of 5% or 6%<br></br> Perfect for investors looking for <br></br>stability such as retirement
            </span>
        ),
        link: "https://bit.ly/2RuFQmH"
    },
 */    {
        image: Image3,
        title: <span>Read our offering Memorandum</span>,
        text: (
            <span>
                A well-informed decision is always good decision. All our Offering Memorandums and Exempt Distributions
                are available for you on the British Columbia Securities Commission website.
            </span>
        ),
        link: "https://www.bcsc.bc.ca/search#sort=Relevance&term=ginkgo%20mortgage%20investment%20corporation"
    },
    {
        image: Image4,
        title: <span>Performance Portfolio</span>,
        text: <span>See how your investments will be utilized in our daily business.</span>,
        link: "/investor/our-performance"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="CardGrid paddingRightL">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={5} sm={6}>
                    <H1 className="GridSection fontSizeLL">Choose your path</H1>
                </Grid>
                <Grid item xs={5} sm={6}>
                    <div className="GridSection paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                        Ginkgo offers steady 8-9% return, making it ideal for retirement planning and spread investing.
                        Whether you are looking for accelerated growth or retirement income, our mortgage-backed will
                        bring you peace of mind.
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
