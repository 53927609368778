import React, { PureComponent } from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../Atoms";
import "./RatingGraphSection.css";
import axios from 'axios'
import { serURL } from '../../../../../../API/url'
import Grid from "@material-ui/core/Grid";
import { LoanPortfolioTable } from "..";

class RatingGraphSection extends PureComponent {  
    constructor(props) {  
        super(props)
          this.state = {
              loans : []
            }
      }
  
      readSPLoans = async() => {
  
       const getLoans = await axios.get(serURL+"/loan"
          ).then((response) => response
          )
          .catch((err) => console.log(err))
      this.setState({loans:getLoans.data.reverse()})
   //   console.log(this.state.loans)
  }
  
  componentDidMount() {
      this.readSPLoans()
  }
  
  render() {
// const RatingGraphSection = props => (
    return (
    <div className="pagePadding-Investor paddingTopXL RatingGraphSection">
        <div className="paddingLeftXL paddingRightXL marginOnMobile">
            <Grid container direction="row">
                <Grid item xs={12}>
                    <H2 className="fontSize28 paddingBottomM">Loan Portfolio Reports</H2>
                </Grid>
            </Grid>
            <div className="paddingTopL paddingBottomL fontSizeM fontWeightNormal lineHeightM bottomTextSection">
                Combined with our performance portfolio report, this micro level view of our loan portfolio gives
                investors a solid understanding of our investment strategy. You will see the composition of each
                mortgage, in which each mortgage does not exceed 85%, and are in diverse geographic areas with large
                populations.
                <br />
                <br />
            </div>
            <div className="paddingTopL paddingBottomL">
                <LoanPortfolioTable loans={this.state.loans}/>   
            </div>
        </div>
    </div>
    )
  }
}

export default pure(RatingGraphSection);
