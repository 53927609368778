import React, { PureComponent } from 'react'
import { Header, Facts, CardsSection, ProductsSection } from './components'
import './Home.css'

class InvestorHome extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='Home'>
               <Header />
     {/*           <Facts /> */}
                <CardsSection />
                <ProductsSection />
            </div>
        )
    }
}

export default InvestorHome