import React, { PureComponent } from 'react'
import { Header, Body, C19Body } from './components'
import {ContactForm} from '../../Molecules'
import './FAQ.css'

class FAQ extends PureComponent {
    
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='FAQ'>
                <Header />
{/*                 <C19Body />
 */}                <Body />
                <ContactForm />
            </div>
        )
    }
}

export default FAQ