import React from 'react'
import { pure } from 'recompose'
import { H2 } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { SingleStep } from './components'

import imageOne from './stepsImages/1.png'
import imageTwo from './stepsImages/2.png'
import imageThree from './stepsImages/3.png'
import imageFour from './stepsImages/4.png'
import imageFive from './stepsImages/5.png'

import './ProgressSection.css'

const steps = [
    {
        number: 1,
        title: 'Submitting your deal',
        description: 'Include with your application: credit bureau, PSA & MLS if any, via email below or to underwrite@ginkgomic.com',
        image: imageOne
    },
    {
        number: 2,
        title: 'Deal Review',
        description: 'Ginkgo Solution Advisors will review and respond within 24 hours. You will be informed at this stage of any conditions, or an even better solution for your client.',
        image: imageTwo
    },
    {
        number: 3,
        title: 'Approved!',
        description: 'You will receive a confirmation from your Solution Advisor that your documents and conditions are satisfactory. Your deal will then be instructed to the lawyer.',
        image: imageThree
    },
    {
        number: 4,
        title: 'Legal Documentation',
        description: "Ginkgo's lawyer will prepare all necessary documentation with the borrower's lawyer. (Borrower can use Ginkgo's lawyer if the loan size is no more than $50,000)",
        image: imageFour
    },
    {
        number: 5,
        title: 'Celebrate!',
        description: 'Your deal is finished when your client receives the funds. Cheer with a glass of wine, and get ready for the next deal!',
        image: imageFive
    },
]

const ProgressSection = props => {

    const isMobile = window.innerWidth < 800

    return (
        <div className={`ProgressSection blackishText pagePadding ${isMobile ? 'paddingTopL paddingBottomM' : 'paddingTopXL paddingBottomL'}`}>
            <OnScrollAnimatior animation='fadeIn' isMboile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <H2 className='centerText'>
                    How it works
                </H2>
            </OnScrollAnimatior>

            <div className={isMobile ? 'paddingTopM paddingBottomM' : 'row paddingTopL paddingBottomL'}>
                {
                    steps.map((step, i) => <div key={i} className={isMobile ? '' : 'rowItem TextAlignCenter paddingRightS paddingLeftS'}>
                        <SingleStep
                            index={i}
                            step={step}
                        />
                    </div>)
                }
            </div>
        </div>
    )
}

export default pure(ProgressSection)
