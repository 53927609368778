import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import './QuoteSection.css'

const QuoteSection = props => {

    const isMobile = window.innerWidth < 800
    return (

    <div className='QuoteSection lightishBackground pagePadding paddingTopXL paddingBottomXL centerText fontSizeM'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <b> Our goal is to give you a true solution to your client's financial problems. </b><br />
            Every deal is meticulously analyzed by our solution advisors, and if we find a better solution, or a way to save on some fees, we will suggest it.
            <br /><br />
            Give us a call or email and we would be happy to discuss your deal.
            </OnScrollAnimatior>
    </div>
)
    }
export default pure(QuoteSection)