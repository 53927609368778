import React, { PureComponent } from 'react'
import { OnScrollAnimatior } from '../../../../Molecules'
import { pure } from 'recompose'
import './PlansTable.css'
import { URL } from 'url'


const plans = ['Ontario', 'British Columbia', 'Manitoba', 'Nova Scotia', 'Alberta']


const data = [
    [ // Ontario
        ['APPRAISER', 'PHONE', 'ADDRESS', 'AREA', 'WEBSITE'],
        ['24 appraisal', '1-800-275-6590', `101 Placer Court Suite A2, 
    Toronto ON M2H 2C9`, 'Toronto, Durham, York, Peel, Halton', 'www.24appraisal.ca',],

        ['Affiliated Property Group', '613-728-3991', `25B Northside Road, 
    Ottawa, ON K2H 8S1`, 'Ottawa, Gatineau', 'www.apgottawa.com',],

        ['Allan Roy, CRA', '705-352-3552', `3552PMB 271 - 190 Minets Point Road,
    Unit 5, Barrie, ON L4N 8J8`, `Barrie, Orillia, Innisful, 
    Springwater, Oro Medonte`, '',],

        ['Appraisals Niagara Real Estate Appraisers Inc.', '905-357-7187', `5773 Depew Avenue, 
    Niagara Falls, ON, L2G 5M2`, `St. Catherines, Niagara Falls, 
    Niagara on the Lake,`, 'www.appraisalsniagara.com',],

        ['Barrons R.E appraisals', '647-962-5796', `506 Flamingo Dr, Sarnia, ON N7V 2J6`, `Sarnia`, '',],

        ['Berk Appraisal Inc', '289-238-8621', `36 Thorpe St. Unit 2, 
    Dundas, ON L9H 1K7`, `Hamilton, Burlington, 
    Oakville, Stoney Creek, Ancaster, 
    Region of Niagara, Brantford`, 'www.berkappraisals.ca',],

        ['Bonafide Appraisals Inc.', '905-901-4926', `106-482 South Service Road East Suite #124, Oakville, ON  L6J 2X6`, `GTA: Burlington, Waterdown, Oakville, 
    Milton, Mississauga, Brampton, Toronto, 
    Richmond Hill, Aurora, Vaughn, Pickering, 
    Oshawa, and more`, 'www.bonafidevalue.com/',],

        ['Carty Gwilym Appraisal Services', '613-724-6020', `1775 Courtwood Crescent #202, Ottawa, ON K2C 3J2
`, `Ottawa`, 'http://www.cartygwilym.com',],

        ['Cornerstone Appraisal Services', '519-822-2126', `12 Aspenwood Place, Guelph, ON N1K 1P3
`, `Guelph`, '',],


        ['Coulson Appraisal Ltd.', '905-333-3140', `53-5100 South Service Rd, 
    Burlington, ON L7L 6A5`, `Hamilton-Wentworth, Stoney Creek, 
    Ancaster, Burlington`, 'www.coulsonappraisal.ca',],

        ['Cross Town Appraisals', '416-652-3456', `399 Four Valley Drive, Unit 18, 
    Vaughan, ON L4K 5Y7`, `Toronto, Rosedale, Forest Hill, High Park, 
    Willowdale, Lawrence Park, Mississauga, 
    Allison, Orangeville, Bradford, Innisfil, 
    Keswick, Newmarket, Aurora, King City, 
    Kleinburg, Maple, Stouffville, Richmond Hill, 
    Woodbridge, Vaughan, Thornhill, Markham, 
    Caledon, Bolton, Brampton, Georgetown, 
    Milton, Oakville, North York, Scarborough, 
    York, East York, Etobicoke, Courtice, Bowmanville, 
    Newcastle, Oshawa, Whitby, Ajax, Pickering`, 'www.crosstownappraisal.com',],

        ['Danford Appraisals', '705-734-2895', `337-80 Bradford Street, 
    Barrie, ON L4N 6S7`, `Simcoe County, GTA, Barrie, Orillia,Innisfil, 
    Bradford, Newmarket, Aurora, Vaughan`, 'www.danfordappraisals.ca',],

        ['Dennis Murphy', '705-737-5100', `136 Crompton Dr, 
    Barrie, ON L4M 6N9`, 'Barrie, Orillia', 'www.dennismurphyappraiser.com',],

        ['Everest appraisals', '905-686-3172', ``, `Peterborough`, 'http://everestappraisals.com',],

        ['F. R. Jordan & Associates', '519-974-0129', `3200 Deziel Dr. Suite 212, 
    Windsor ON N8W 5K8`, 'Windsor', '',],

        ['Gifford Appraisals', '905-683-2637', `P.O. Box 603, 
    Pickering, ON L0H 1H0`, `Durham Region, Oshawa, Ajax, Pickering, Whitby, 
    Clarington, Scarborough, Markham, Stouffville`, '',],

        ['Home Value Inc. (HVI)', '416-871-9224', ``, `Toronto, York, Durham, Simcoe, Hamilton & Niagara region and more`, 'https://hviorder.ca',],
        ['L.A. Mirotta & Company', '519-837-3692', `70 Hazelwood Dr.`, `Guelph`, '',],


        ['Mallette , Beaton & Associates', '705-671-2944', `901 Corsi Hill, Sudbury​, ON, P3E 6A4`, `Sudbury`, '',],

        ['McCulloch & Associates', '613-967-2414', `465 Mississauga Rd, Belleville, ON K8N 4Z7`, `Belleville`, '',],


        ['M.Machel & Associates Ltd.',	'519-578-5444',	'508 Riverbend Drive, 217a, Kitchener, ON N2A 3S2',	'Kitchner, Waterloo',	'https://www.machel.com/'],

        ['Musso Appraisals', '519-741-8700', '109 College St, Kitchener, ON N2H 5A2', 'Kitchner, Waterloo',	'https://www.mussoappraisals.com'],

        ['Ontario Appraisal Corporation.', '416-674-1041', `153 High Park Avenue`,
            'Greater Toronto Area', 'www.ontarioappraisal.ca',],

        ['Precise Real Estate Appraising Inc.', '519-580-7732', ``,
            'Kitchener, Ontario', 'https://preciseappraising.com/',],

        ['Reliable Appraisal', '905-712-8887', `5659 McAdam Road #A6, 
    Mississauga, Ontario, L4Z 1N9`, 'Greater Toronto Area', 'https://reliableappraisal.ca/',],

        ['RJ Lyons Real Estate Appraisal Services Inc ', '519-672-0485', `Upper-217 Wharncliffe Rd South, 
    London ON N6J 2L2`, 'London', 'www.rjlyons.ca',],

        ['Russell Appraiser Services', '705-791-1980', `366 Emms Drive, 
    Barrie, ON L4N 8J4`, 'Barrie', 'www.russellappraisers.ca/',],

        [`Schinkel Real Estate 
        & Appraisals Inc.`, '905-387-0100', `#205 - 1059 Upper James Street, 
        Hamilton, ON L9C 3A6`, 'Hamilton, Ancaster, Burlington, Stoney Creek', 'www.schinkelappraisals.ca',],

        [`S.W. Irvine 
    & Associates Inc.`, '519 763 5956', `155 - Suffolk St, 
        Guelph, N1H 2K7`, 'Guelph Ontario', 'https://www.swiappraisals.com/',],

        ['Terry Thomas & Associates', '705-443-1949', `206 Stn Main Collingwood, L9Y 3Z5`, `Owen sound`, '',],

        ['Walker & Walker Appraisal Ltd', '905-639-0235', `214-2349 Fairview Street, 
    Burlington, ON L7R 2E3`, `Oakville, Milton, Burlington, Hamilton, Stoney Creek`, 'https://www.walkerandwalkerappraisal.com/',],

        ['York Simcoe Appraisal Corp', '905-836-1028', `846 Haggart St, 
    Peterborough, ON K9J 2X8`, `York Region, Durham Region, Simcoe County, Newmarket`, '',],
    ],
    [ // British Columbia
        ['APPRAISER', 'PHONE', 'ADDRESS', 'AREA', 'WEBSITE'],
        ['A- 1 Appraisals', '250-861-8440', `201-1583 Ellis Street, Kelowna, BC V1Y 2A7`, 'Greater Kelowna, Central Okanagan Region', 'www.kelownaa1appraisals.com'],
        ['Lawrenson Walker Realty Advisors Ltd.', '604-535-1494', `#200 1678 128th Street, Surrey, BC V4A 3V3`, 'Greater Vancouver, Lower Mainland, Sea to sky corridor, Greater Victoria, Greater Nanaimo', 'www.lawrensonwalker.com'],
        ['Macintosh Appraisals', '604-522-3900', `#401 - 555 Sixth Street, New Westminster, BC V3L 5H1`, 'Vancouver, North Vancouver, West Vancouver, Richmond, Burnaby, Coquitlam, Surrey, Langley, Delta, Port Moody, Port Coquitlam, Pitt Meadows, Maple Ridge, Abbotsford, Mission, Ladner, Tsawwassen, Anmore, Belcarra, Bowen Island, Lions Bay, Aldergrove, Cloverdale, Fort Langley', 'www.macintoshappraisals.com'],
        ['Niemi Laporte and Dowle Appraisals', '250-412-3182', `100-1803 Douglas Street, Victoria, BC, V8T 5C3`, 'Victoria and surrounding area', 'www.nldappraisals.com'],
    ],
    [ // Monitoba
        ['APPRAISER', 'PHONE', 'ADDRESS', 'AREA', 'WEBSITE'],
        ['Dennis T. Browaty & Associates Ltd.', '204-942-7574', `565-167 Lombard Avenue, Winnipeg, MB R3B 0V3`, 'Winnipeg', ''],
        ['Hink Appraisals services', '204-896-3979', `34-3900 Grant Ave., PO Box 21033, Winnipeg, MB R3R 3R2`, 'Winnipeg', ''],
        ['Pearson Appraisal', '204-282-4000', `116 Pinehurst Crescent, Winnipeg, MB R3K 1Y7`, 'City of Winnipeg , Steinbach, Selkirk, Portage la Prairie', ''],
        ['Red River Appraisal Services', '204-371-5833', `P.O. Box 1180, Niverville(Southern Man), MB R0A 1E0`, 'Winnipeg', 'www.redrivergroup.ca'],
    ],
    [ // Nova Scotia
        ['APPRAISER', 'PHONE', 'ADDRESS', 'AREA', 'WEBSITE'],
        ['Boutiler & Associates Ltd.', '902-435-2200', `175 Main Street, Suite 205 Dartmouth, NS B2X 1S1`, 'Halifax Regional Municipality, Hants, Colchester County, Western Pictou County, Eastern Lunenburg County', ''],
        ['Fennell & Associates Appraisers Ltd.', '902-453-5051', `3600 Kempt Road, Suite 209, Halifax, NS B3K 4X8`, 'Halifax Regional Municipality, Hants County, Lunenburg County, Halifax, Dartmouth, Bedford, Sackville, Beaver Bank, Windsor Junction, Lakeside, Beechville, Timberlea, Hubley, Hammonds Plains, Upper Tantallon, Stillwater Lake, Tantallon, Glen Haven, French Village, Seabright, Glen Margaret, Hacketts Cove, Indian Harbour, Peggys Cove, Dover, McGraths Cove, Bayside, Head of St Margarets Bay, Boutiliers Point, Ingramport, Black Point, Queensland, Hubbards, Prospect, Hatchet Lake, Brookside, Whites Lake, Terence Bay, Fall River, Waverley, Lake Fletcher, Wellington, Oakfield, Grand Lake, Herring Cove, Purcells Cove, Fergusons Cove, Bear Cove, Portuguese Cove, Ketch Harbour, Sambro, Pennant, Williamswood, Harrietsfield, Cole Harbour, Eastern Passage, Minesville, Lake Echo, Porters Lake', ''],

        ['Kempton Appraisals Ltd.', '902-465-3000', `376 Portland Street, Dartmouth, NS B2Y 1K8`, 'Nova Scotia, PEI, HRM, Halifax County, Hants County, Lunenburg County, Colchester County, Truro way, Cumberland County', 'www.kemptonappraisals.com'],
    ],
    [ // Alberta
        ['APPRAISER', 'PHONE', 'ADDRESS', 'AREA', 'WEBSITE'],
        ['Appraisal Management Group Ltd', '403-216-6803', `4308 Chapel Rd NW Calgary T2L1A4`, 'Calgary', ''],
        ['Atkinson & Associates', '403-212-1103', `201, 20 Sunpark Plaza SE, Calgary, Alberta T2X 3T2`, 'Calgary', 'https://www.atkinsonappraisals.com/'],
        ['Eagleson, Ho & Associates', '403-650-2223', `83006 #14 - 11625, Elbow Dr SW, Calgary, AB T2W 1G0`, 'Calgary', 'http://www.eha-appraisals.com/'],
        ['Residential Valuations Services Inc', '403-275-5384', '#170, 612 - 500 Country Hills Blvd. NE, Calgary, AB  T3K 5K3', 'Calgary', 'https://www.rvscalgaryappraisers.com/'],
        ['Blackmud Appraisals Ltd.', '780-965-8972', '14032 23 Ave NW #233, Edmonton, AB T6R 3L6', 'Edmonton', 'http://blackmudappraisals.com/'],
        ['Cartwright Appraisals', '780-802-8540', '4144 97 St NW, Edmonton, AB T6E 5Y6', 'Edmonton', 'https://www.cartwright-appraisals.com/'],
        ['Dundas Appraisals', '780-945-6565', '17008 90 Ave NW, Edmonton, AB T5T 1L6', 'Edmonton', ''],
        ['Fletchers Appraisal Services', '780-440-9808', '177 Granlea Crescent NW, Edmonton, AB T6L 1A8', 'Edmonton', 'http://realtycentral.ca/'],
        ['Harrison Bowker Valuation Group', '403-351-1535', '555-1201 5th Street SW, Calgary, AB T2R 0Y6', 'Calgary', 'https://www.harrisonbowker.com/'],
        ['Knight & Company Appraisals', '780-486-9545', 'PO Box 69133, Edmonton, AB, T6V 1G7', 'Edmonton', 'https://knightappraisals.com/'],
    ],

]








class PlansTable extends PureComponent {

    state = {
        activeTab: 0
    }

    formatUrl(url){
        if (url.startsWith('http')){
            return url;
        }else{
            return "http://"+url;
        }
    }
    renderColumn(index, value){

        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);

        if (value.match(regex)) {
            return <a href={this.formatUrl(value)}><i class="fas fa-link"></i></a>;
        } else {
            return value;
        }
    }
    render() {
        const isMobile = window.innerWidth < 800
        return (
            <div className={`PlansTable pagePadding paddingBottomXL ${!isMobile ? 'paddingTopXL' : 'paddingTopL'}`}>
                <div className='tableWrap paddingTopL'>
                    <div className='underLine primaryBackground'></div>
                    <div className='thead'>
                        {plans.map((plan, i) => (
                            <div
                                className={`${i === this.state.activeTab ? 'primaryBackground lightText' : 'lightishBackground'} paddingS fontSizeM roundedTopBig center bold`}
                                onClick={() => this.setState({ activeTab: i })}
                                key={i}
                            >
                                {plan}
                            </div>
                        ))}
                    </div>
                    <table className='fontSizeXS'>
                        <tbody>
                            {
                                data[this.state.activeTab].map((row, i) => (
                                    <tr key={i}>
                                        <OnScrollAnimatior className={`${i % 2 === 0 ? 'lightishBackground' : ''}`} animation='fadeIn' isMobile={isMobile}  preAnimationStyles={{ opacity: 0 }}>
                                            <div className='tableRow'>
                                                {row.map((value, index) => !index ?
                                                    <td key={index}>
                                                        <b>
                                                            {row[0]}
                                                        </b>
                                                    </td>
                                                    : <td className={`bold`} key={index}>{this.renderColumn(index,value)}</td>)}
                                            </div>
                                        </OnScrollAnimatior>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default pure(PlansTable)