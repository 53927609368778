import React, { PureComponent } from "react";
import { Header, ReportSection } from "./components";
import { ContactForm, DownloadAppSection, ReadyToInvestSection } from "../../../../Molecules";

class InvestmentProducts extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="InvestmentProducts">
                <Header />
                <ReportSection />
                <ReadyToInvestSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default InvestmentProducts;
