import React from 'react'
import { pure } from 'recompose'
import { QuestionAndAnswer, OnScrollAnimatior } from '../../../../Molecules'
import { Button } from '../../../../Atoms'
import './FAQSection.css'

const questionsAndAnswers = [
    {
        question: 'What are your lending guidelines?',
        answer: <div>
            Ginkgo specializes in residential mortgage and can consider commercial deals on a case to case basis.
            <ul>
                <li>We can finance residential properties up to 80% LTV and commercials up to 70% LTV. </li>
                <li>We lend to towns/ cities with a population of 50,000 or more in British Columbia., Ontario, Manitoba, and Nova Scotia.</li>
            </ul>
        </div>
    },
    {
        question: 'What is the timeframe for funding?',
        answer: `We usually provide a response within 24 hours of receiving a deal. If it is more complicated and requires a bundle it can take 
        up to 48 hours. If all the documentation is provided upfront including appraisal report, income verification, and the borrowers lawyer 
        cooperates we can usually fund within 2-3 business days.`
    },
    {
        question: 'What documents do you require for submission ?',
        answer: `We need a Filogix application and credit bureaus in order to underwrite the deal.An explanation regarding the purpose
        of the loan and the exit strategy for the borrowers would assist with the underwriting, If you are unsure, we can always
        be reached at 416.901.5133 for discussion.`
    },
    {
        question: 'Will Ginkgo allow for a holding company or corporation to go on title ?',
        answer: 'Ginkgo will allow for a holding company or corporation to go on title provided that the directors will personally guarantee the loan.'
    },
]


const FAQSection = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className={`FAQSection pagePadding ${isMobile ? 'paddingTopL paddingBottomL' : 'paddingTopXL paddingBottomXL'} lightishBackground blackishText`}>
            {
                questionsAndAnswers.map(({ question, answer }, i) => <QuestionAndAnswer question={question} answer={answer} />)
            }
            <div className={`${isMobile ? 'paddingTopL' : 'paddingTopXL'} center`}>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <Button to='appraisers'>Ginkgo Approved Appraisers List</Button>
                </OnScrollAnimatior>
            </div>
        </div>
    )
}

export default pure(FAQSection)