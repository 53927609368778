import React from 'react'
import { pure } from 'recompose'
import {Button, H2} from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import { SingleListItem } from './componets'
import './AppraisalSection.css'


const AppraisalSection = props => {

    const isMobile = window.innerWidth < 800

    return (
    <div className={`AppraiserSection blackishBackground pagePadding paddingTopL paddingBottomL lightText `}>
        <div className={`AppraiserList ${isMobile ? 'paddingBottomL' : 'paddingBottomXL'} pagePadding paddingTopXL blackishText`}>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <H2 className='centerText lightText'>Don’t forget the appraisal!</H2>
            </OnScrollAnimatior>
            <div className='paddingTopM fontSizeS centerText fadeInUp lightText'>
                We appraised the appraisers for you. <br />
                You’re in good hands.            </div>
            <div className='paddingTopL center'>
                <Button to='/appraisers'>Ginkgo Approved Appraisers List</Button>
            </div>
        </div>
    </div>
    )
}
export default pure(AppraisalSection)