import React from "react";
import { pure } from "recompose";
import "./SingleCard.css";

const SingleCard = props => (
    <div className="SingleCard paddingBottomL">
        <div className="roundedBig">
            {props.images.length === 1 ? (
                <div className="paddingRightL NoPaddingRightOnMobile">
                    <img className="bigImage roundedBig" src={props.images[0]} alt="" />
                </div>
            ) : (
                <div className="paddingRightL NoPaddingRightOnMobile">
                    <img className="smallImage roundedBig" src={props.images[0]} alt="" />

                    <img className="smallImage roundedBig" src={props.images[1]} alt="" />
                </div>
            )}
        </div>
    </div>
);

export default pure(SingleCard);
