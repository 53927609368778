import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">What's a Mic</H1>
        <div className="marginOnMobile paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            A MIC is a great investment opportunity for those looking to grow their money in the time-tested real estate
            market
        </div>
    </div>
);

export default pure(Header);
