
import React from 'react'
import { pure } from 'recompose'
import { SingleOffice } from './components'
import { SocialIcons, OnScrollAnimatior } from '../../../../Molecules'
import './OfficesSection.css'
import { Button } from "../../../../Atoms";

const OfficesSection = props =>{

    const isMobile = window.innerWidth < 800
    return (
    <div className='OfficesSection pagePadding paddingTopL paddingBottomL'>
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile}  preAnimationStyles={{ opacity: 0 }}>
            <SingleOffice
                office='Toronto Head Office'
                phone='416-901-5133'
                address={<span> 243 Consumers Rd #203,
                <br /> Toronto, Ontario, M2J 4W8</span>}
            />
                <div className='centerText paddingBottomL'>
                    <Button onClick={() => { window.open('https://bit.ly/2LixQS7')}}> Parking Instruction </Button>
                </div>
        </OnScrollAnimatior>
        
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile}  preAnimationStyles={{ opacity: 0 }}>
            <SingleOffice
                office='Vancouver Virtual Office'
 /*                address={<span> 4000 No.3 Road, Suite 6040
                        <br />Richmond BC V6X 0J8</span>}
                 phone='604-248-1900'
 */               withBottomBorder
            />
        </OnScrollAnimatior>
        <div className='centerText paddingTopL paddingBottomL'>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile}  preAnimationStyles={{ opacity: 0 }}>
                <div className='bold paddingTopM fontSizeL paddingBottomL'>
                    Follow us on Social Media
                </div>
            </OnScrollAnimatior>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile}  preAnimationStyles={{ opacity: 0 }}>
                <SocialIcons width={35} />
            </OnScrollAnimatior>
        </div>
    </div>
    )
                }

export default pure(OfficesSection)