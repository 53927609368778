import React from 'react'
import { pure } from 'recompose'
import { Provider } from 'react-redux'
import store from './Store'
import Router from './Router/Router'
import WithStyles from './Styles/WithStyles'

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <WithStyles>
          <Router />
        </WithStyles>
      </div>
    </Provider>
  )
}

export default pure(App)