import React, { PureComponent } from 'react'
import './Dropdown.css'

class Dropdown extends PureComponent {

    state = {
        items: [
            { isActive: true, text: 'EN', id: 1 },
            { isActive: false, text: 'FR', id: 2 },
            { isActive: false, text: 'ES', id: 3 },
        ]
    }

    setActive = function (id) {
        const { items } = this.state
        items.forEach(item => {
            if (item.id === id)
                item.isActive = true
            else
                item.isActive = false
        })
        this.setState({ items: [...items] })
    }.bind(this)

    render() {
        const { setActive, state } = this
        const { open, items } = state

        const activeItem = items.filter(item => item.isActive)
        const unactiveItems = items.filter(item => !item.isActive)

        return (
            <div className={`${open ? 'open' : ''} Dropdown lightText fontSizeXS`}>
                <div className='head row'>
                    <div className='activeValue bold'>
                        {activeItem[0].text}
                    </div>
                    <div className='arrow'></div>
                </div>
                <div className='itemsWrap darkText'>
                    {
                        unactiveItems.map(item => (
                            <div
                                onClick={() => setActive(item.id)}
                                className='ddItem paddingRightM paddingLeftM paddingTopS paddingBottomS bold'
                                key={item.id}
                            >
                                {item.text}
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default Dropdown