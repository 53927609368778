import React from 'react'
import { pure } from 'recompose'
import { OnScrollAnimatior } from '../../../../../../Molecules'
import image from './image.png'
import './SingleStep.css'

const SingleStep = props => {
    const isMobile = window.innerWidth < 800
    const delay = 100 * props.index
    return (
        <div className={`SingleStep ${isMobile ? 'paddingBottomXL' : ''}`}>
            <OnScrollAnimatior delay={delay} animation='pulse'>
                <div className='imageWrap'>
                    <img src={props.step.image || image} alt='' />
                </div>
            </OnScrollAnimatior>
            <div className={`circle bold primaryBackground center fontSizeL ${isMobile ? 'bold' : ''}`}>
                {props.step.number}
            </div>
            <OnScrollAnimatior delay={delay} animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className={`fontSizeM bold ${isMobile ? 'paddingTopS' : 'paddingTopM'}`}>
                    {props.step.title}
                </div>
                <div className={`${isMobile ? 'paddingTopS fontSizeS' : 'paddingTopM fontSizeXS'}`}>
                    {props.step.description}
                </div>
            </OnScrollAnimatior>
        </div>
    )
}

export default pure(SingleStep)
