import React from 'react'
import { pure } from 'recompose'
import TeamMember from '../TeamMember/TeamMember'
import { terence, yvonne, william, alexandra, henry } from './members'
import './MembersSection.css'

// Adding chunck proporty
Object.defineProperty(Array.prototype, 'chunk', {
    value: function (chunkSize) {
        var array = this;
        return [].concat.apply([],
            array.map(function (elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    }
})

const members = [
    {
        image: henry,
        name: 'Henry Tse',
        title: 'CEO',
        bio: 'As co-founder of Ginkgo Mortgage Investment Corporation, Henry constantly pushes the vision of the company to achieve higher ground and larger milestones of success. With his many years of experience, he is always diligently analyzing the industry, allowing him to foresee and set trends that have kept the team current and the company at a competitive advantage.'
    },
    {
        image: william,
        name: 'WILLIAM CHEUNG',
        title: 'Director, Solution Advisory',
        bio: "With methodical precision, William meticulously reviews and processes all mortgage applications that are submitted to Ginkgo MIC. William's methods have improved risk mitigation and in turn increased earnings to valuable Ginkgo MIC investors. His thorough investigations into each application has thereby contributed significantly to the solid performance and strong foundation of Ginkgo MIC."
    },
    {
        image: terence,
        name: 'Terence Yu',
        title: 'Business Development Manager',
        bio: "Terence has worked previously as a Mortgage Coordinator for a leading principal broker and over 40 agents. With his background, Terence relates very well to the broker experience, which lends to his dedication in going above and beyond to save agents’ deals. His expertise with appraisals and legal fees have helped brokers get the best product for their client."
    },
    {
        image: yvonne,
        name: 'YVONNE LEUNG',
        title: 'Investor Relations',
        bio: "Yvonne has been with Ginkgo MIC since our early years. With over 20 years in customer service and event planning, she has been at the forefront in creating engaging educational seminars to ensure we deliver on our promise: to be transparent and to keep our investors well informed."
    },
]

const MembersSection = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className={`MembersSection lightishBackground pagePadding ${isMobile ? 'paddingTopL' : 'paddingTopXL paddingBottomXL'}`}>
            <div className={!isMobile ? 'paddingTopXL paddingBottomXL' : ''}>
                {
                    members.chunk(2).map((row, i) => (
                        <div key={i} className={!isMobile ? 'row paddingTopL paddingBottomL' : ''}>
                            {row.map((member, index) => <TeamMember
                                image={member.image}
                                name={member.name}
                                title={member.title}
                                bio={member.bio}
                                key={index}
                            />)}
                        </div>))
                }
            </div>
        </div>
    )
}

export default pure(MembersSection)