
import React, { PureComponent } from 'react'
import { Header, VideoSection, QuestionsSection, AgentTier } from './components'
import { ContactForm } from '../../Molecules'
import './LoyaltyPrograms.css'

class LoyaltyPrograms extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='LoyaltyPrograms lightishBackground'>
                <Header />
                <VideoSection />
                <AgentTier/>
                <QuestionsSection />
                <ContactForm />
            </div>
        )
    }
}

export default LoyaltyPrograms