import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../Atoms";
import "./ResourcesSection.css";

const ResourcesSection = props => (
    <div className="ResourcesSection withBackground">
        <H1 className="fontSizeLL paddingTopXXL">Resources</H1>
        <div className="paddingTopS fontWeightNormal plainText fontSizeS lineHeightM fadeInUp">
            Want to learn more about Ginkgo? See our Performance Portfolio
        </div>
    </div>
);

export default pure(ResourcesSection);
