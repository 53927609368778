import React from 'react'
import { pure } from 'recompose'
import './H2.css'

const H2 = props => (
    <h1 className={`H2 fontSizeL ${props.className}`}>
        {props.children}
    </h1>
)

export default pure(H2)