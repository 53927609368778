import React from 'react'
import { FadeLoader } from 'react-spinners'
import { pure } from 'recompose'
import './AppLoader.css'

const AppLoader = (props) => (
    <div className='AppLoader center paddingL'>
        <FadeLoader color='#ffffff' />
    </div>
)

export default pure(AppLoader)