import React from "react";
import { pure } from "recompose";
import "./ReviewCard.css";

import quoteImage from "./icon_quote.png";
const defaultValues = {
    name: "Paul Smith",
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const ReviewCard = props => (
    <div className="ReviewCard paddingBottomML">
        <div className="reviewSection  lightishBackground roundedBig">
            <div className="innerReviewSection paddingL centerText">
                <img className="quoteImage paddingTopS paddingBottomS" src={quoteImage} alt="" />
                <div className="fontSizeM fontWeightNormal">{props.text || defaultValues.text}</div>
                <div className="fontSizeS paddingTopL fontWeightNormal">{props.name || defaultValues.name}</div>
                <div className="fontSizeXS fontWeightNormal">{props.title || defaultValues.title}</div>
            </div>
        </div>
    </div>
);

export default pure(ReviewCard);
