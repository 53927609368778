const initialState = {
    isLoggedIn: false,
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'LOG_OUT':
            return { ...state, isLoggedIn: false }
        case 'LOG_IN':
            return { ...state, isLoggedIn: true }
        default:
            return state
    }
}