import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header lightText centerText'>
        <H1>Lending Area</H1>
        <div className='paddingTopS plainText fontSizeXS fadeInUp'>
        See below for Ginkgo’s Lending Area.
        </div>
    </div>
)

export default pure(Header)