import React, { PureComponent } from 'react'
import { Header, MembersSection, DirectorsSection } from './components'
import { ContactForm } from '../../Molecules'
import './OurTeam.css'

class OurTeam extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='OurTeam'>
                <DirectorsSection />
                <Header />
                <MembersSection />
                <ContactForm />
            </div>
        )
    }
}

export default OurTeam