import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../../../../Atoms'
import image from '../cardOne.png'
import './SingleCard.css'

const defaultValues = {
    image,
    title: 'Bank-level Service',
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
}

const SingleCard = props => (
    <div className='SingleCard paddingS'>
        <div className='row'>
            <img className='roundedTopBig' src={props.image || image} alt='' />
        </div>
        <div className='textWrap paddingM primaryBackground roundedBottomBig'>
            <H2>
                {props.title || defaultValues.title}
            </H2>
            <div className='paddingTopM fontSizeS paddingBottomM'>
                {props.text || defaultValues.text}
            </div>
            {/* <Button /> */}
        </div>
    </div>
)

export default pure(SingleCard)