import React, { PureComponent } from 'react'
import { Header, AppraisalRqm, PlansTable, SubmitDealSection } from './components'
import { ContactForm } from '../../Molecules'
import './Appraisers.css'

class Appraisers extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className='Appraisers'>
                <Header />
                <AppraisalRqm />
                <PlansTable />
                <SubmitDealSection />
                <ContactForm />
            </div>
        )
    }
}

export default Appraisers