import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Link } from '../../Atoms'
import { connect } from "react-redux";
import { SocialIcons, Dropdown, SearchBox } from "../index";
import { logoClean, handshake, phone, email, burger, close, signin, powerofsale } from "../../Assets";
import "./PrimaryNavigation.css";

class PrimaryNavigation extends PureComponent {
    state = {
        open: null,
        shouldReDirect: false,
        redirectUrl: "",
        website: 'https://investor.ginkgomic.com',
    };

    close = function() {
        if (!this.state.open) return;
        this.setState({ open: false });
    }.bind(this);
    updateUserType = function(userType) {
        if (userType === "broker") {
            this.setState({
                shouldReDirect: true,
                redirectUrl: "/"
            });
        } else if (userType === "investor") {
            this.setState({
                shouldReDirect: true,
                redirectUrl: "/investor"
            });
        }

        this.props.setUserType(userType);
    }.bind(this);

    render() {
        const isMobile = window.innerWidth < 800;
        const { open, shouldReDirect, redirectUrl } = this.state;
        const { userType, location } = this.props;
        return (
            <div className={`PrimaryNavigation ${isMobile ? "fontSizeM" : "fontSizeXXS"} ${open ? "open" : ""}`}>
                {shouldReDirect ? <Redirect to={redirectUrl} /> : <div></div>}
                {!isMobile || open ? (
                    <div className="topNav primaryBackground pagePadding">
                        <div className="userTypeWrap">
                            <span
                                className={`topNavItem roundedTopSmall ${
                                    userType === "broker" && !location.pathname.includes("investor")
                                        ? "lightBackground"
                                        : "lightBlueBackground"
                                }`}
                                onClick={() => this.updateUserType("broker")}
                            >
                                <rb className="brokerTabWrap">MORTGAGE&nbsp;BROKER</rb>
                            </span>
                            <span
                                className={`topNavItem roundedTopSmall ${
                                    userType === "investor" || location.pathname.includes("investor")
                                        ? "lightBackground"
                                        : "lightBlueBackground"
                                }`}
                                onClick={() => this.updateUserType("investor")}
                            >
                                <rb className="brokerTabWrap">INVESTOR</rb>
                            </span>
                        </div>
                        <div className="row rightSide lightText slideInRight">
                            {userType === "investor" || location.pathname.includes("investor") ? (
                              <div className="signinType">
                                <a href={this.state.website} target="_blank">
                                    <img src={signin} style={{width:'80px',marginTop:'6px'}}/></a>
                              <input 
                                type="radio" 
                                id="websiteRadio1" 
                                name="websiteRadio" 
                                value="https://investor.ginkgomic.com"
                                checked={this.state.website === "https://investor.ginkgomic.com"}
                                onChange={e => this.setState({ website: e.target.value })}
                              />
                              
                              <label className="investor" htmlFor="websiteRadio1">Investor</label>
                              <input 
                                type="radio" 
                                id="websiteRadio2" 
                                name="websiteRadio" 
                                value="https://sf.ginkgomic.com"
                                checked={this.state.website === "https://sf.ginkgomic.com"}
                                onChange={e => this.setState({ website: e.target.value })}
                              />
                              <label className="advisor" htmlFor="websiteRadio2">Advisor</label>
                                </div>
    ) : (
                                <Link onClick={this.close} to="/submitadeal" className="row iconRow">
                                    <div className="iconWrap center">
                                        <img src={handshake} alt="" />
                                    </div>
                                    <span>
                                        <div className="darkText">Submit a Deal</div>
                                    </span>
                                </Link>
                            )}
                            <a href="tel:14169015133" className="row iconRow">
                                <div className="iconWrap center">
                                    <img src={phone} alt="" />
                                </div>

                                <span>
                                    <div class="darkText">(1) 416-901-5133</div>
                                </span>
                            </a>
                            <a
                                href={`mailto:${
                                    userType === "investor" || location.pathname.includes("investor")
                                        ? "investor@ginkgomic.com"
                                        : "underwrite@ginkgomic.com"
                                }`}
                                className="row iconRow"
                            >
                                <div className="iconWrap center">
                                    <img src={email} alt="" />
                                </div>

                                <span>
                                    <div className="darkText">
                                        {userType === "investor" || location.pathname.includes("investor")
                                            ? "investor@ginkgomic.com"
                                            : "underwrite@ginkgomic.com"}
                                    </div>
                                </span>
                            </a>

                            <SocialIcons />
                            {/* <Dropdown /> */}
                        </div>
{/*                         <a href="https://powerofsale.ginkgomic.com" target="_blank">
                              <img src={powerofsale} style={{width:'36px',marginTop:'1px'}}/></a>
 */}                    </div>
                ) : (
                    <span></span>
                )}

                <div className="navigationContent pagePadding">
                    <div className="menuLogoRowWrap slideInLeft">
                        <Link onClick={this.close} to="/">
                            <img className="logo" src={logoClean} alt="" />
                        </Link>
                        <div className="iconWrap" onClick={() => this.setState({ open: !open })}>
                            <img src={open ? close : burger} alt="" />
                        </div>
                    </div>
                    {!isMobile || open ? (
                        <div>
                            {userType === "broker" && !location.pathname.includes("investor") ? (
                                <div className="mobileNavigationContent fadeIn">
                                    <Link
                                        onClick={this.close}
                                        to=""
                                        className={`menuItem ${location.pathname === "/" ? "active" : ""}`}
                                    >
                                        HOME
                                    </Link>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            ABOUT US
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/aboutus"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/aboutus" ? "active" : ""
                                                }`}
                                            >
                                                OUR STORY{" "}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/team"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/team" ? "active" : ""
                                                }`}
                                            >
                                                TEAM{" "}
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/faq"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/faq" ? "active" : ""
                                                }`}
                                            >
                                                FAQ
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to="/newsandevents"
                                        className={`menuItem ${location.pathname === "/newsandevents" ? "active" : ""}`}
                                    >
                                        NEWS & EVENTS
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to="/products"
                                        className={`menuItem ${location.pathname === "/products" ? "active" : ""}`}
                                    >
                                        PRODUCTS
                                    </Link>

                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/appraisers" || location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            DEAL PROCESS
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/submitadeal"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/submitadeal" ? "active" : ""
                                                }`}
                                            >
                                                DEAL PROCESS
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/appraisers"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/appraisers" ? "active" : ""
                                                }`}
                                            >
                                                {" "}
                                                APPRAISERS
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/lendingarea"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/lendingarea" ? "active" : ""
                                                }`}
                                            >
                                                {" "}
                                                LENDING AREA
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to="/loyaltyprograms"
                                        className={`menuItem ${
                                            location.pathname === "/loyaltyprograms" ? "active" : ""
                                        }`}
                                    >
                                        LOYALTY PROGRAM
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to="/contact"
                                        className={`menuItem ${location.pathname === "/contact" ? "active" : ""}`}
                                    >
                                        CONTACT
                                    </Link>
                                    {/* <SearchBox /> */}
                                </div>
                            ) : (
                                /* investor */
                                <div className="mobileNavigationContent fadeIn">
                                    <Link
                                        onClick={this.close}
                                        to="/investor"
                                        className={`menuItem ${location.pathname === "/" ? "active" : ""}`}
                                    >
                                        HOME
                                    </Link>
                                    <Link
                                        onClick={this.close}
                                        to="/investor/investment-products"
                                        className={`menuItem ${location.pathname === "/products" ? "active" : ""}`}
                                    >
                                        PRODUCTS
                                    </Link>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            INVESTMENT HUB
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/investor/investment-hub"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/investment-hub" ? "active" : ""
                                                }`}
                                            >
                                                Investment Hub
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/how-to-invest"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/how-to-invest" ? "active" : ""
                                                }`}
                                            >
                                                How to Invest
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/faq"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/faq" ? "active" : ""
                                                }`}
                                            >
                                                FAQ
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/what-is-mic"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/what-is-mic" ? "active" : ""
                                                }`}
                                            >
                                                Whats a MIC
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/investment-strategy"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/investment-strategy"
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                Investment Strategy
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            PORTFOLIO
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/investor/our-performance"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/our-performance" ? "active" : ""
                                                }`}
                                            >
                                                Performance Portfolio
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/analyst-rating"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/analyst-rating" ? "active" : ""
                                                }`}
                                            >
                                                Analyst Rating
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/loan-portfolio"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/loan-portfolio" ? "active" : ""
                                                }`}
                                            >
                                                Loan Portfolio
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/fund-portfolio"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/fund-portfolio" ? "active" : ""
                                                }`}
                                            >
                                                Fund Portfolio
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/historical-return"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/historical-return" ? "active" : ""
                                                }`}
                                            >
                                                Historical Returns
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/lending-map"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/lending-map" ? "active" : ""
                                                }`}
                                            >
                                                Lending Map
                                            </Link>
                                            <Link
                                                onClick={(e) => {
                                                    this.close();
                                                    window.open("https://discover.ginkgomic.com/powerofsale", "_blank");
                                                }}
                                                to="/investor"
                                                className={`ddItem hoverPrimaryBackground`}
                                            >
                                                Power of Sale Listing
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            NEWS & EVENTS
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/investor/events"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/events"
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                News &amp; Events
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/gallery"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/gallery" ? "active" : ""
                                                }`}
                                            >
                                                Gallery
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="menuItem dropdown">
                                        <span
                                            className={`menuItem target 
                                        ${
                                            location.pathname === "/aboutus" ||
                                            location.pathname === "/team" ||
                                            location.pathname === "/faq" ||
                                            location.pathname === "/appraisers"
                                                ? "active"
                                                : ""
                                        }`}
                                        >
                                            ABOUT US
                                            <div className="arrow"></div>
                                        </span>
                                        <div className="dropdownItems lightBackground">
                                            <Link
                                                onClick={this.close}
                                                to="/investor/about-us"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/about-us" ? "active" : ""
                                                }`}
                                            >
                                                About Us
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/our-story"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/our-story" ? "active" : ""
                                                }`}
                                            >
                                                Our Story
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/team"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/team" ? "active" : ""
                                                }`}
                                            >
                                                Team
                                            </Link>
                                            <Link
                                                onClick={this.close}
                                                to="/investor/why-us"
                                                className={`ddItem hoverPrimaryBackground ${
                                                    location.pathname === "/investor/why-us" ? "active" : ""
                                                }`}
                                            >
                                                Why Us
                                            </Link>
                                        </div>
                                    </div>
                                    <Link
                                        onClick={this.close}
                                        to="/contact"
                                        className={`menuItem ${location.pathname === "/contact" ? "active" : ""}`}
                                    >
                                        CONTACT
                                    </Link>
                                    {/* <SearchBox /> */}
                                </div>
                            )}
                        </div>
                    ) : (
                        <span></span>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUserType: userType => {
            if (userType !== "broker" && userType !== "investor") throw "User type must be a broker or an investor";
            dispatch({
                type: "SET_USER_TYPE",
                payload: userType
            });
        }
    };
}

function mapStateToProps(state) {
    return {
        ...state.app
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrimaryNavigation));
