import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">News and Events</H1>
        <div className="paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            Official announcements from Annual General Meetings
        </div>
    </div>
);

export default pure(Header);
