import React, { PureComponent } from "react";
import { Header, ReportSection } from "./components";
import { ContactForm, DownloadAppSection, PastPortfolioSection } from "../../../../Molecules";

class OurPerformance extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="OurPerformance">
                <Header />
                <ReportSection />
                <PastPortfolioSection />
                <DownloadAppSection />
                <ContactForm />
            </div>
        );
    }
}

export default OurPerformance;
