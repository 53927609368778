import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./Header.css";

const Header = props => (
    <div className="Header lightText withBackground centerText">
        <H1 className="fontSizeLL">FAQ</H1>
        <div className="marginOnMobile paddingM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
            Our vision is to be a prominent leader of the private mortgage industry by providing comprehensive solutions
            to all stakeholders.
        </div>
    </div>
);

export default pure(Header);
