import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { pure } from 'recompose'

/*function UtmLink({ to, className, onClick, children }) {
  const queryString = useLocation().search;

  return (
    
      <Link to={to + '/' + queryString} className={className} onclick={onClick}>{children}</Link>
    
  );
}*/

function UtmLink({ to, children , ...props}) {
  const queryString = useLocation().search;

  return (
    
      <Link to={to + '/' + queryString} {...props}>{children}</Link>
    
  );
}



export default pure(UtmLink);