import React from 'react'
import { pure } from 'recompose'
import { H2, Button } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import './QuoteSection.css'

const QuoteSection = props => {
    const isMobile = window.innerWidth < 800
    return (

        <div className='QuoteSection'>

            <div className='darkBackground lightText paddingTopXL paddingBottomXL pagePadding fontSizeM'>
                <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    "A great company you can trust! we had a rush deal and need to close in two days, and we made it because of Ginkgo!! fast turnover and underwriting, exceptional customer services!"
                    <div className='paddingTopM'>
                        <b>Zijun Yu, google review</b>
                    </div>
                </OnScrollAnimatior>

            </div>
            <div className='deal withBackground center pagePadding'>
                <OnScrollAnimatior animation='fadeInUp' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div className='primaryBackground paddingTopL paddingBottomL roundedBig'>
                                <H2 className='centerText ready'>Ready?</H2>

                        <div className='row paddingTopM'>
                            <div className='rowItem TextAlignCenter paddingRightL paddingLeftL paddingTopM paddingBottomL'>
                                <H2>Submit a Deal</H2>
                                <div className='TextAlignCenter paddingTopM paddingBottomM fontSizeM'>
                                    You won't find our level of service anywhere else - we work together with you to solve your headache deals. Send it in and let us take care of the rest!
                        </div>
                                <Button to='submitadeal'> Let's show you how </Button>
                            </div>
                            <div className='line darkerBackground'></div>
                            <div className='rowItem TextAlignCenter paddingRightL paddingLeftL paddingTopM paddingBottomL'>
                                <H2>Your Experience Matters</H2>
                                <div className='TextAlignCenter paddingTopM paddingBottomM fontSizeM'>
                                    Customer satisfaction is extremely important to us. We'd love to hear your feedback on your recent experience!
                                </div>
                                <div className='TextAlignCenter'>
                                <Button onClick={() => { window.open('https://bit.ly/3u4tbWU')}}> Write Us a Google Review </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </OnScrollAnimatior>
            </div>
        </div>
    )
}

export default pure(QuoteSection)