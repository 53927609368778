import React, { PureComponent } from 'react'
import './OnScrollAnimatior.css'

class OnScrollAnimatior extends PureComponent {

    state = {
        animate: false
    }

    wrapperElement = React.createRef()
    distanceToBeAnimated = 50
    topVisabilityThatMustAnimate = 100

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll)
        setTimeout(this.onScroll, 100)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll)
    }

    animate() {
        this.setState({ animate: true })
        window.removeEventListener("scroll", this.onScroll)
        this.props.onSuccess && this.props.onSuccess(this)
    }

    onScroll = function() {

        if (!this.wrapperElement.current)
            return

        const position = this.wrapperElement.current.getBoundingClientRect()

        const fullyVisible = position.top >= 0 && position.bottom <= window.innerHeight - this.distanceToBeAnimated
        const visibleEnough = window.innerHeight - position.top > this.topVisabilityThatMustAnimate

        if (fullyVisible || visibleEnough)
            if (this.props.delay)
                setTimeout(this.animate.bind(this), this.props.delay)
            else
                this.animate()

    }.bind(this)


    render() {
        const { animate } = this.state
        return (
            <div
                ref={this.wrapperElement}
                className={`${animate ? this.props.animation : ''} ${this.props.className || ''}`}
                style={animate ? (this.props.isMobile? {opacity: 0} :{}) : this.props.preAnimationStyles}
            >
                {this.props.children}
            </div>
        )
    }
}

export default OnScrollAnimatior