import React from "react";
import { pure } from "recompose";
import { H1, H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";

const BodySection = props => {
    return (
        <div className="pagePadding-Investor paddingTopL paddingBottomXL BodySection">
            <Grid className="" justify="center" container direction="row">
                <Grid item>
                    <H2 className="fontSize28 headerSection">Fresh content</H2>
                    <a href="/investor/what-is-mic">
                        <div className="whatIsMicSection withBackground">
                            <div className="lightText paddingLeftL bottomInnerSection paddingBottomL paddingRightXL">
                                <H1 className="fontSizeLL">What’s a MIC</H1>
                                <div className="greenLine primaryBackground"></div>
                                <div className="fontSize26 fontWeight300fadeInUp paddingTopM">
                                    Not sure what a Mortgage Investment Corporation does for investors? Let us show you.
                                </div>
                            </div>
                        </div>
                    </a>
                </Grid>
                <Grid item>
                    <div className="zeroLeftPaddingMobile paddingLeftML">
                        <a href="/investor/investment-strategy">
                            <div className="headerSection subHeadersection fontWeightNormal fontSizeS lineHeightM rightText">
                                <div className="paddingTopS paddingRightM hiddenOnMobile">See all</div>
                            </div>
                            <div className="paddingBottomML">
                                <div className="investmentStrategiesSection smallerColumnSection withBackground">
                                    <div className="lightText paddingLeftS bottomInnerSection paddingBottomS">
                                        <div className="shortGreenLine primaryBackground"></div>
                                        <H2 className="">Investment Strategies</H2>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="/investor/how-to-invest">
                            <div className="investWithGinkgoSection smallerColumnSection withBackground">
                                <div className="lightText paddingLeftS bottomInnerSection paddingBottomS">
                                    <div className="shortGreenLine primaryBackground"></div>
                                    <H2 className="">How to Invest with Ginkgo</H2>
                                </div>
                            </div>
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default pure(BodySection);
