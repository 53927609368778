import React from 'react'
import { pure } from 'recompose'
import image from './image.png'
import './PostBody.css'

const PostBody = props => (
    <div className='PostBody pagePadding paddingTopXL paddingBottomXL row'>
        <div className='rowItem paddingRightL paddingLeftL'>
            <div className='fontSizeM paddingBottomM paddingTopM bold'> April 25, 2019</div>
            <div className='blackishText fontSizeXS'>
                <div className='paddingBottomM'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                     when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </div>
                <div className='paddingBottomM'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                     when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </div>
                <div className='paddingBottomM'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </div>
                <div className='bold paddingBottomM'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                     Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </div>
                <div className='paddingBottomM'>
                    To read more about our program <b>Click Here</b> 
                </div>
            </div>
        </div>
        <div className='rowItem'>
            <img src={image} alt='' />
        </div>
    </div>
)

export default pure(PostBody)