import React, { PureComponent } from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import "./BodySection.css";
import { HistorialReturnsData } from "..";
import axios from 'axios'
import { serURL } from '../../../../../../API/url'


class BodySection extends React.Component {  
    constructor(props) {  
        super(props)
          this.state = {
              returnS1 : [],
              returnS2 : []
            }
      }


readSPReturn = async() => {
  
    const getHisRetS1 = await axios.get(serURL+"/hisreturnS1"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({returnS1: getHisRetS1.data.reverse()})
  //   console.log(this.state.returnS1)
    const getHisRetS2 = await axios.get(serURL+"/hisreturnS2"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({returnS2: getHisRetS2.data.reverse()})
  //  console.log(this.state.returnS2)
 }

componentDidMount() {
   this.readSPReturn()
 //   this.state.returns = this.state.returns.data.slice(17,18)
}

render() {

    return (
// const BodySection = props => (
    <div className="pagePadding-Investor paddingTopXL BodySection">
        <div className="paddingLeftXL paddingRightXL centerText marginOnMobile">
            <H1 className="fontSizeLL">Historical Returns</H1>
            <div className="FirstSectionInnerContnet paddingTopM fontWeightNormal plainText fontSizeM lineHeightM fadeInUp">
                Ginkgo MIC offers investors stable monthly income. As well, the underlying asset value of your
                investment will not fluctuate as stocks and bonds will. The steady stream of returns is ideal for
                retirement planning and offers peace of mind. It also offers the opportunity for borrowing at lower
                rates and making money on the 'spread' - the rate difference between your borrowing costs and investment
                gains.
            </div>
            <div className="paddingTopL paddingBottomXL">
                <HistorialReturnsData historylistS1={this.state.returnS1} historylistS2={this.state.returnS2}/>
            </div>
        </div>
    </div>
    );
};
};
export default BodySection;
