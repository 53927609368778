import React from "react";
import { H2 } from "../../../../../../../Atoms";
import { pure } from "recompose";
import "./SingleCard.css";

const SingleCard = props => (
    <div className="QuestionCard paddingBottomML">
        <div className="">
            <div>
                <H2 className="fontSizeS fontWeightBold paddingTopM">{props.question}</H2>
                <div className="fontSizeS fontWeightNormal paddingTopS">{props.answer}</div>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
