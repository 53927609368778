import React from 'react'
import { pure } from 'recompose'
import { BlogPostCard } from '../../../../Molecules'
import './PostsSection.css'

const PostsSection = props => (
    <div className='PostsSection pagePadding paddingTopXL paddingBottomXL'>
        <div className='row'>
            <BlogPostCard />
            <BlogPostCard />
        </div>
        <div className='row paddingTopXL'>
            <BlogPostCard />
            <BlogPostCard />
        </div>
        <div className='row paddingTopXL paddingBottomL'>
            <BlogPostCard />
            <BlogPostCard />
        </div>
    </div>
)

export default pure(PostsSection)