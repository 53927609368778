import React from "react";
import SingleCard from "./SingleCard/SingleCard";
import { OnScrollAnimatior } from "../../../../../Molecules";
import { H1 } from "../../../../../Atoms";
import { pure } from "recompose";

import cardOne from "./cardOne.png";
import cardTwo from "./cardTwo.png";
import cardThree from "./cardThree.png";

import Grid from "@material-ui/core/Grid";

import "./CardsSection.css";
import HorizontalCard from "./HorizontalCard/HorizontalCard";

const horizontalCards = [
    {
        image: cardOne,
        title: <span> See this month's portfolio performance</span>,
        text: <span>Read all about our new monthly portfolio here. Catch up on all you news here</span>,
        buttonTitle: "learn more",
        link: "/investor/fund-portfolio"
    },
    {
        image: cardTwo,
        title: <span> "Very Good Return-to-Risk"</span>,
        text: (
            <span>
                Ginkgo receives high rating of 2- by independent research group, Fundamental Research Corporation.
            </span>
        ),
        buttonTitle: "learn more",
        link: "/investor/analyst-rating"
    }
];

const verticalCards = [
    {
        image: cardThree,
        title: <span>Get Our Performance Stats</span>,
        text: (
            <span>
                We know you need to understand every aspect of a company before you invest in it. So we put it all out
                in the open for you
            </span>
        ),
        buttonTitle: "See performance portfolio",
        link: "/investor/fund-portfolio"
    }
];

const CardsSection = props => {
    const isMobile = window.innerWidth < 1300;
    const HorizontalCards = () =>
        horizontalCards.map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <div className="paddingRightML">
                        <HorizontalCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                    </div>
                </OnScrollAnimatior>
            </Grid>
        ));

    const VerticalCards = () =>
        verticalCards.map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));
    const VerticalMobileCards = () =>
        horizontalCards.concat(verticalCards).map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="CardsSection withBackground">
            <H1 className="fadeIn fontSizeLL header">Ginkgo Investment Resource</H1>
            {isMobile ? (
                <div className="paddingL">
                    <Grid container direction="row" justify="enter">
                        {VerticalMobileCards()}
                    </Grid>
                </div>
            ) : (
                <Grid container direction="row" justify="enter">
                    <Grid item xs="7" direction="column">
                        <Grid container direction="row" justify="flex-end">
                            {HorizontalCards()}
                        </Grid>
                    </Grid>
                    <Grid item xs="5" direction="column">
                        {VerticalCards()}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default pure(CardsSection);
