import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../../Atoms";
import "./TimelineCard.css";

import Grid from "@material-ui/core/Grid";
const defaultValues = {
    number: 1,
    title: "Bank-level Service",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
};

const TimelineCard = props => (
    <div className="TimelineCard">
        <Grid container direction="row">
            <Grid item xs={1}>
                <div className="fadeInUp NumberBulletDiv">
                    <div className="NumberBullet">
                        <H2 className="fontSizeMM centerText">{props.number || defaultValues.number}</H2>
                    </div>
                    {props.number < 5 ? <div className="VerticalLine primaryBackground"></div> : <div />}
                </div>
            </Grid>
            <Grid className="paddingLeftML" item xs={11}>
                <H2 className="lineHeightM fontSizeMM paddingTopSM">{props.title || defaultValues.title}</H2>
                <div className="fontSizeS lineHeightM fontWeightNormal paddingTopSM paddingRightL">
                    {props.text || defaultValues.text}
                </div>
            </Grid>
        </Grid>
    </div>
);

export default pure(TimelineCard);
