import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header withBackground center centerText lightText'>
        <div>
            <H1 className='fadeIn'>
                Deal Process
            </H1>
            <div className='paddingTopM fontSizeS fadeInUp'>
                Have a deal? Send it in!
                <br />
<br />Every deal funded is automatically counted towards your Power Agent status, and you only need three to go on vacation!
                <br />
                <br />Plus, receive a $200 gift card on our very first funded deal.
            </div>
        </div>
    </div>
)

export default pure(Header)