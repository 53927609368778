import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import arrowLeft from './arrowLeft.svg'
import arrowRight from './arrowRight.svg'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './Carusel.css'

const Carusel = props => (
    <div className={`Carusel ${props.className ? props.className : ''} ${props.darkNav ? 'darkNav' : ''}`}>
        <OwlCarousel
            className="owl-theme"
            loop={props.disableLoop ? false : true}
            items={props.items || 1}
            autoplay={true}
            autoplayTimeout={5000}
            autoplayHoverPause={true}
            nav={props.nav}
            navText={[
                `<img class='arrowLeft' src="${arrowLeft}" alt="" />`,
                `<img class='arrowRight' src="${arrowRight}" alt="" />`
            ]}
        >
            {props.children.map((child, i) => (
                <span className='item' key={i}>
                    {child}
                </span>
            ))}
        </OwlCarousel>
    </div>
)

export default Carusel