import React from "react";
import { pure } from "recompose";
import "./SingleCard.css";

import quoteImage from "./icon_quote.png";
const defaultValues = {
    name: "Paul Smith",
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const isMobile = window.innerWidth < 800;
const SingleCard = props => (
    <div className="SingleCard paddingBottomML">
        <div className="reviewSection  lightishBackground roundedBig">
            <div className="innerReviewSection paddingM centerText">
                <img className="quoteImage" src={quoteImage} alt="" />
                <div className={`${isMobile ? "fontSizeXS" : "fontSizeXXS"} fontWeightNormal`}>
                    {props.text || defaultValues.text}
                </div>
                <div className="fontSizeXS paddingTopS fontWeightNormal">{props.name || defaultValues.name}</div>
            </div>
        </div>
    </div>
);

export default pure(SingleCard);
