import React from "react";
import { pure } from "recompose";
import { H1, H2, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import SingleCard from "./SingleCard/SingleCard";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import Logo from "./logo.png";
const reviews = [
    {
        name: <span>Daniel Oh</span>,
        text: (
            <span>
                They are very friendly and care about their investors. From many, one thing I am very impressed is their
                transparency on their operation. No hiding, no lying. From an investor's perspective, it is one of the
                most critical points to consider when it comes to investment. They answered all the questions I asked.(I
                ask a lot until I get full clarification and confidence in myself.) And the answers were all clear
                rather than vague. I will definitely refer this opportunity to my friends who are interested in mortgage
                investment.
            </span>
        )
    },
    {
        name: <span>Addison Cham</span>,
        text: (
            <span>
                Been investing with them for 6 years, have been getting stable returns and the staffs are very helpful.
                The text I get for the interest return is something I look forward to every month.
            </span>
        )
    },
    {
        name: <span>Vienna Kwan</span>,
        text: (
            <span>
                A very well organized and precise company. They manage and take care of your investment with high
                professionalism. They are very transparent and provide detail information to investors with timely
                update on the market situation and the status of clients' investment. Their customer services is
                particularly magnificent and knowledgeable. They follow through your queries and always there to render
                help when you need them. I am a very satisfied customer and always recommend and refer this fund to my
                relatives and friends.
            </span>
        )
    },
    {
        name: <span>Michelle Ng</span>,
        text: (
            <span>
                From beginning to end the whole team at Ginkgo was prompt, professional and made me feel at ease. Yvonne
                Leung called me the same day I first emailed them. I then had a meeting with Henry Tse the CEO, and he
                explained to me very well what the company does and the risks involved. The folks here are experienced,
                knowledgeable, and they know how to talk to their clients. I am just starting to learn about the exempt
                market, in working with any investment company I wanted to work with one that was trustworthy and with
                people who would explain things to me and guide me along the way. Between Yvonne’s strong client
                relationship skills, Henry as the CEO, Elaine preparing the forms and the courteousness I received at
                reception, it’s been a very positive experience.
            </span>
        )
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ name, title, text }, i) => (
            <Grid item xs={6}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard name={name} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="BodySection ">
            <div className="fistSection pagePadding-Investor withBackground">
                <div className="paddingTopXXL">
                    <div className="fadeInUp primaryBackground paddingL roundedBig">
                        <div className="centerText">
                            <H1 className="fontSizeLL paddingBottomS">Ginkgo</H1>
                            <H2 className="fontSizeM paddingBottomS">Read what analysts say about Ginkgo</H2>
                            <div className="fontWeightNormal lineHeightM fontSizeS paddingLeftM paddingRightM paddingBottomM">
                                <p>
                                    We will not compromise on quality and diligence, which is why Ginkgo has a strong
                                    reputation as a dependable, veteran leader in the private mortgage industry. As a
                                    result, when many MICs collapsed during the 2017 real estate boom and bust cycle, we
                                    were able to serve consistently while also growing our company 5% within the same
                                    fiscal year.
                                </p>
                            </div>
                            <div className="paddingBottomM">
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = "/investor/analyst-rating";
                                    }}
                                >
                                    Read what analysts say about Ginkgo
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondSection pagePadding-Investor paddingTopXXL">
                <Grid container direction="row">
                    <Grid item xs={4}>
                        <div className="fadeInUp">
                            <div>
                                <H1 className="fontSizeLL paddingBottomS">Why investors love Ginkgo</H1>
                                <div className="innerContent fontWeightNormal lineHeightM fontSizeS">
                                    We know communication and accountability is of utmost importance to our investors.
                                    That is why we ensure investors enjoy peace of mind with clear, monthly reports,
                                    dividend announcements, and Annual General Meetings.
                                </div>
                            </div>
                            <div className="paddingTopLL">
                                <Button
                                    onClick={() => {
                                        window.open("/investor/gallery");
                                    }}
                                >
                                    SEE OUR PHOTOS
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="paddingLeftM" item xs={8}>
                        <Grid className={`${isMobile ? "paddingTopL" : ""}`} container>
                            {ReviewCards()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
{/*             <div className="fadeInUp secondSection pagePadding-Investor paddingTopXL centerText">
                <H2 className="fontSize28 paddingBottomS">
                    Ginkgo is an Accredited Business with the Better Business Bureau
                </H2>
                <div className="paddingTopML fontWeightNormal lineHeightM fontSizeS">
                    Accredited since March 3, 2018
                </div>
                <div className="textContent paddingTopM fontWeight300 lineHeightM fontSize26">
                    We want you to have peace of mind and feel confident about your investment. That is why we are
                    registered with the Better Business Bureau, and adhere to the outlined Standards of Trust for
                    ethical business practices.
                    <br />
                    <br />
                    <div class="underline greenText">
                        <a href="https://www.bbb.org/ca/on/north-york/profile/mortgage-lenders/ginkgo-mortgage-investment-corporation-0107-1373779">
                            See our BBB Profile
                        </a>
                    </div>
                </div>
                <img className="LogoImage" src={Logo} alt="" />
            </div>
 */}        </div>
    );
};
export default pure(BodySection);
