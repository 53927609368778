import React, { Component } from "react";
import { pure } from "recompose";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./MapSection.css";

import Grid from "@material-ui/core/Grid";

import gtaIcon from "./image/gta.png";
import ontarioIcon from "./image/ontario.png";
import albertaIcon from "./image/gta.png";
import manitobaIcon from "./image/manitoba.png";
import bcIcon from "./image/bc.png";

import SingleCard from "./SingleCard/SingleCard";

const locationCards = [
 /*   {
        image: gtaIcon,
        title: <span>Greater Toronto Area</span>,
        text: <span>info@form.com</span>
    },
    {
        image: ontarioIcon,
        title: <span>Ontario</span>,
        text: <span>info@form.com</span>
    },
    {
        image: manitobaIcon,
        title: <span>Manitoba</span>,
        text: <span>info@form.com</span>
    },
    {
        image: albertaIcon,
        title: <span>Alberta</span>,
        text: <span>info@form.com</span>
    },
    {
        image: bcIcon,
        title: <span>British Columbia</span>,
        text: <span>info@form.com</span>
    } */
];

class MapSection extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        const isMobile = window.innerWidth < 800;

        const LocationCards = () =>
            locationCards.map(({ image, title, text, buttonTitle }, i) => (
                <Grid item xs={2}>
                    <OnScrollAnimatior
                        key={i}
                        animation="fadeInUp"
                        delay={i * 100}
                        preAnimationStyles={{ opacity: 0 }}
                        isMobile={isMobile}
                        onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                    >
                        <SingleCard image={image} title={title} text={text} buttonTitle={buttonTitle} />
                    </OnScrollAnimatior>
                </Grid>
            ));
        return (
            <div className="pagePadding-Investor paddingTopLL MapSection centerText">
                <Grid container justify="center" direction="row">
                    {LocationCards()}
                </Grid>

                <div className="paddingTopLLL paddingBottomXL">
                    <iframe
                        name="gMap"
                        src={`https://www.google.com/maps/d/u/0/embed?mid=1psynGly8aABZ-bIzXskMwpPdqRQ&ll=44.00943469516914%2C-79.16015573061276&z=9`}
                        title="Lending Map"
                    ></iframe>
                </div>
            </div>
        );
    }
}

export default pure(MapSection);
