import React, { PureComponent } from 'react'
import { pure } from 'recompose'
import { Header, PlansTable, SPHeader, SpecialPromotion, QuoteSection, SubmitDealSection } from './components'
import { ContactForm } from '../../Molecules'
import './Products.css'
import { propTypes } from 'react-bootstrap/esm/Image'

class Products extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <div className='Products'>
                <Header />
                <PlansTable />
                <SPHeader />
                <SpecialPromotion />
                {/* <MortgageSection /> */}
                <QuoteSection />
                <SubmitDealSection />
                <ContactForm />
            </div>
        )
    }
}

export default pure(Products)