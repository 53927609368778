import React from "react";
import { pure } from "recompose";
import { H2, H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import image1 from "./employee.png";
import image2 from "./ginkgo-image.png";

import TimelineCard from "./TimelineCard/TimelineCard";
const timelineCards = [
    {
        number: 1,
        title: <span>2012: Total fund size reached $10 million</span>,
        text: <span></span>
    },
    {
        number: 2,
        title: <span>2015: Total fund size reached $30 million</span>,
        text: <span></span>
    },
    {
        number: 3,
        title: <span>2016: $15 million in dividends paid to over 500 investors</span>,
        text: <span></span>
    },
    {
        number: 4,
        title: <span>2018: Total fund size reached $50 million</span>,
        text: <span>Received 2- (“Very Good Return-to-Risk") rating by independent research group</span>
    },
/*     {
        number: 5,
        title: <span>2019: Awarded A+ grading by Better Business Bureau</span>,
        text: <span> </span>
    },
 */    {
        number: 5,
        title: <span>2020: Total fund size reached $60 million</span>,
        text: <span> </span>
    },
    {
        number: 6,
        title: <span>2023: Total fund size reached $100 million</span>,
        text: <span>Received 2- (“Very Good Return-to-Risk") rating by independent research group</span>
    },
    {
        number: 7,
        title: <span>2023: $40 million dollars in dividends paid to over 900 investors</span>,
        text: <span> </span>
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    const TimelineCards = () =>
        timelineCards.map(({ number, title, text }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <TimelineCard number={number} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="BodySection">
            <div className="primaryBackground">
                <Grid container direction="row" justify="flex-end">
                    <Grid item>
                        <div className="fadeInUp topGridSection">
                            <div className="topSection paddingTopXL paddingRightXXL marginOnMobile">
                                <H2 className={`fontSize40 ${!isMobile ? "paddingBottomM" : ""}`}>
                                    We are Stronger Together
                                </H2>
                                <div className="innerContent fontWeightNormal lineHeightM fontSizeS">
                                    Established in 2011 with our headquarters in Toronto, we provide stable,
                                    high-return investment vehicles in the mortgage industry. Leveraging the
                                    timelessness of real-estate in two of Canada's strongest and largest cities for real
                                    estate, we are strategically positioned for safe, long-term growth for our
                                    investors.
                                    <br />
                                    <br />
                                    We are different from a syndicated mortgage - as a mortgage investment corporation,
                                    we provide alternative lending solutions to many borrowers, extended across multiple
                                    properties to control and minimize risk. Serving nation-wide from the very east in
                                    Halifax, to the far west in Vancouver, we have a well-diversified portfolio to back
                                    our preferred shares.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <img src={image1} alt="" />
                    </Grid>
                </Grid>
            </div>
            <div className="timelineContent paddingTopXXL">
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <div className="timelineLeftSection">
                            <div className="bottomSection">
                                <H1 className="fontSizeLL">Founded in October 2011</H1>
                                <H2 className="lineHeightM fontSizeMM">and we've been growing ever since</H2>
                                <div className="fontSizeS lineHeightM fontWeightNormal">
                                    Securities of a MIC are purchased through an Exempt Market Dealer (EMD) as required
                                    by the FSCO. Here's how you can get started on growing your wealth:
                                </div>
                                <div className="buttonRow">
                                    <Button
                                        onClick={() => {
                                            window.open("http://broker.ginkgomic.com/investor/how-to-invest");
                                        }}
                                    >
                                        READ MORE
                                    </Button>
                                </div>
                                <img
                                    src={image2}
                                    className="timelineLeftSectionImage paddingTopLL paddingBottom200"
                                    alt=""
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="fadeInUp ">
                            <div className="timelineLeftSection">
                                <H1 className="fontSizeLL paddingBottomLL">Ginkgo Company Timeline</H1>
                            </div>
                            <div>{TimelineCards()}</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default pure(BodySection);
