import React from 'react'
import { pure } from 'recompose'
import { withRouter, useLocation } from 'react-router-dom'
import { Button, Link } from '../../Atoms'
import { SocialIcons } from '../../Molecules'
import { logo } from '../../Assets'
import BBB from './BBB.png'
import './Footer.css'

const Footer = ({ location }) => {
    const isMobile = window.innerWidth < 800

    const subscribeBox = (
        <div className='row subscribeWrap'>
            <input className='roundedSmall' placeholder='Your Email' />
            <Button >Subscribe</Button>
        </div>
    )

    let year = new Date().getFullYear();
    //const queryString = useLocation().search;

    return (
        <div className={`Footer ${isMobile ? 'fontSizeM' : 'fontSizeXXS'}`}>
            <div className='darkerBackground'></div>
            <div className='lightishBackground withBackground pagePadding paddingTopL paddingBottomL'>
                {
                    isMobile ? subscribeBox : <span></span>
                }
                <div className='row items'>
                    <Link to="/" className={`menuItem ${location.pathname === '/' ? 'active' : ''}`} >
                        HOME
                </Link>
                    <Link to="/aboutus" className={`menuItem ${location.pathname === '/aboutus' ? 'active' : ''}`}>
                        ABOUT US
                </Link>
                    <Link to="/newsandevents" className={`menuItem ${location.pathname === '/newsandevents' ? 'active' : ''}`}>
                        NEWS AND EVENTS
                </Link>
                    <Link to="/products" className={`menuItem ${location.pathname === '/products' ? 'active' : ''}`}>
                        PRODUCTS
                </Link>
                    <Link to="/submitadeal" className={`menuItem ${location.pathname === '/submitadeal' ? 'active' : ''}`}>
                        SUBMIT A DEAL
                </Link>
                    <Link to="/loyaltyprograms" className={`menuItem ${location.pathname === '/loyalty' ? 'active' : ''}`}>
                        LOYALTY PROGRAM
                </Link>
                    <Link to="/contact" className={`menuItem ${location.pathname === '/contact' ? 'active' : ''}`}>
                        CONTACT
                </Link>

                     {
                        !isMobile ? subscribeBox : <span></span>
                    }
{/*                    <a href="https://www.bbb.org/ca/on/north-york/profile/mortgage-lenders/ginkgo-mortgage-investment-corporation-0107-1373779" target="_blank">
                        <img className='bbb' src={BBB} alt='' />
                    </a>
 */}

                </div>
                <div className='row items secondRow'>
                    <Link to="/blogfeed" className={`menuItem ${location.pathname === '/blogfeed' ? 'active' : ''}`}>
                        OUR BLOG
                </Link>
                    <Link to="/faq" className={`menuItem ${location.pathname === '/faq' ? 'active' : ''}`}>
                        FREQUENTLY ASKED QUESTIONS
                </Link>
                     <a href="terms-and-conditions.htm" target="_blank">
                        TERMS AND CONDITIONS
                    </a>
                 </div>
                <div className='centerText paddingTopM paddingBottomM'>
                    <img className='logo' src={logo} alt='' />

                    <div className='paddingTopM'>
                        <SocialIcons />
                    </div>
                    <div className='darkText paddingTopM fontSizeXXS'>
                        (c){year} Ginkgo | All rights reserved
                    </div>
                    <p className='privacy paddingTopM fontSizeXXS'>
                        Ginkgo MIC is committed to respecting the privacy of
                        individuals and recognizes a need for the appropriate management and protection of any
                        personal information that you agree to provide to us. We will not share your information
                        with any third party outside of our organization, other than as necessary to fulfill your
                        request.                    
                    </p>
                </div>

            </div>
            <div className='darkBackground'></div>
        </div>
    )
}

export default pure(withRouter(Footer))