import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header withBackground center centerText lightText'>
        <div>
            <H1>Broker FAQ</H1>
            <div className='fontSizeM paddingTopM fadeInUp'>
                Broker Frequently Asked Questions
            </div>
        </div>
    </div>
)

export default pure(Header)