import React from "react";
export const commonQuestions = [
    {
        question: <span>What is the minimum amount I can invest?</span>,
        answer: (
            <span>
                The minimum amount is $10,000, and will require the investor to have an Eligible Investor Certificate.
                Investors who choose to invest over $100,000 will require an Accredited Investor Certificate. Both
                certificates can be completed with your Exempt Market Dealer (EMD). An appointment with an EMD can also
                be made through us by calling us at 1-855-901-5133.
            </span>
        )
    },
    {
        question: <span>When will dividends be paid?</span>,
        answer: (
            <span>
                Dividends are paid on the 15th of every month. You will receive an SMS notifying you of the amount paid
                to your Ginkgo account. An e-mail will also be sent to you regarding your investment’s overall
                performance.
            </span>
        )
    },
    {
        question: <span>How will I know my investment is doing well?</span>,
        answer: (
            <span>
                Just like a bank, we will issue a monthly report to you via e-mail to inform you of your investment's
                performance and standing.
                <br />
                <br /> You will also be invited to our Annual General Meeting, so you can have a better understanding of
                the company's performance. It is a great opportunity to also meet the staff who manage your investments
                and be informed of projected economic outlooks. Therefore, it is very important to provide up-to-date
                contact information in which we can inform you about your investment. Your personal information is held
                privately, only used for the regular performance of the corporation. It will not be sold to third
                parties.
            </span>
        )
    },
    {
        question: <span>I heard about Ponzi schemes. How do I know Ginkgo MIC is not one of those?</span>,
        answer: (
            <span>
                This is a good question. A Ponzi scheme does not have a real business. It is a fraudulent investment
                operation that pays returns to its investors from the investor's own money or the money paid by
                subsequent investors, rather than from profit earned by the company's operation. The Ponzi scheme
                usually entices new investors by offering higher returns than other investments, in the form of
                short-term returns that are either abnormally high or unusually consistent. Perpetuation of the high
                returns requires an ever-increasing flow of money from new investors to keep the scheme going.
            </span>
        )
    },
    {
        question: <span>What is the difference between a first and second mortgage?</span>,
        answer: (
            <span>
                The main difference between a first and second mortgage is debt seniority. <br />
                <br />
                In the event of a default, both first and second mortgagees have the right to foreclose on the property
                and sell it on the market for repayment. However, the first mortgagee is at the first position to get
                the proceeds; and the second mortgagee will be entitled to the remaining amount. Since second mortgages
                are behind the first mortgage, they are considered more risky and therefore demand a higher return.
            </span>
        )
    }
];
