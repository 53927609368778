import React from "react";
import { pure } from "recompose";
import { H2, H1, Button } from "../../../../../../Atoms";
import { Carusel, OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";
import { topCarouselCards, bulletPoints } from "./BodySectionConstants.js";
import Grid from "@material-ui/core/Grid";

import mainImage from "./main-image.png";
import BulletCard from "./BulletCard/BulletCard";
import { baseURL } from "../../../../../../API";

const BodySection = props => {
    const isMobile = window.innerWidth < 850;
    const CarouselCards = () =>
        topCarouselCards.map(({ title, text, image, buttonTitle }, link, i) => (
            <div className="caruselItem">
                <Grid container direction="row" justify="flex-end">
                    <Grid item>
                        <div className="topSection paddingTopXL paddingRightL marginOnMobile">
                            <H1 className="fontSizeLL lineHeightSM paddingBottomM">{title} </H1>
                            <div className="fontSizeS lineHeightM fontWeightNormal paddingRightL">{text}</div>
                            <div className="paddingTopM paddingBottomM">
                                <Button
                                    className="leftButton"
                                    onClick={() => {
                                        window.open("/investor/our-performance");
                                    }}
                                >
                                    See our Performance Portfolio
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="fadeInUp ">
                            <img src={image} className="rightSideImage paddingBottom200" alt="" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        ));

    const BulletCards = () =>
        bulletPoints.map((text, i) => (
            <Grid item xs={6}>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <BulletCard text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="BodySection">
            <Carusel dots>{CarouselCards()}</Carusel>
            <div className="pagePadding-Investor paddingTopXL paddingBottomXL zeroPaddingBottomOnMobile">
                <img className="BottomImage roundedBig" src={mainImage} alt="" />
                <Grid container className="paddingTopL paddingBottomXL">
                    <Grid item xs={6} className="paddingRightXL">
                        <H2 className="fontSizeMM">Benefits of investing in a MIC</H2>
                        <div className="fontSizeS lineHeightM fontWeightNormal">
                            Stable growth, diversified risk! Receive the coveted growth from real estate without the
                            excessive financial burden. Your funds in a MIC are also distributed amongst many
                            properties, which lowers your risk.
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <div className="paddingLeftL zeroMarginLeftOnMobile">
                            <Grid container>{BulletCards()}</Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default pure(BodySection);
