import React, { PureComponent }  from 'react'
import { pure } from 'recompose'
import { Button } from '../../../../Atoms'
import { OnScrollAnimatior } from '../../../../Molecules'
import './PlansTable.css'
import axios from 'axios'
import { serURL } from '../../../../API/url'
/* import FileDownload  from 'js-file-download'
import { SPFetchClient } from "@pnp/nodejs-commonjs";
import { Lists, sp } from "@pnp/sp-commonjs";
 */

const plans = ['', 'First Mortgage', 'Second Mortgage', '1st & 2nd Mtg Bundle', 'Bridge', 'Non-Resident**', 'Condo']

const ProductRow = ({
    product: {
        Title: rowName,
        FirstMortgage: firstMortgage,
        SecondMortgage: secondMortgage,
        Bundle: bundle,
        Bridge: bridge,
        NonResident: nonResident,
        Condo: condo,
    }
}) => (
    <tr>
        <td className="centerText bold">{rowName}</td>
        <td className="centerText bold">{firstMortgage}</td>
        <td className="centerText bold">{secondMortgage}</td>
        <td className="centerText bold">{bundle}</td>
        <td className="centerText bold">{bridge}</td>
        <td className="centerText bold">{nonResident}</td>
        <td className="centerText bold">{condo}</td>
    </tr>
);        

class PlansTable extends PureComponent {
    constructor(props) {  
        super(props)
          this.state = {
              productsPlan : [],
              fileData: Blob
            }
      }

readProducts = async() => {

    const productData = await axios.get(serURL+"/products"
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({productsPlan: productData.data})
 }

 readTslip = async() => {
    const configA={headers:{'Content-Type': 'application/octet-stream', Accept:'application/octet-stream'}, data:{}}
    const fileBuff = await axios.get(serURL+"/download",configA
       ).then((response) => response
       )
       .catch((err) => console.log(err))
     this.setState({fileData: Object.values(fileBuff.data)})

     console.log(this.state.fileData)
     console.log(fileBuff)
 }

componentDidMount() {
    this.readProducts()
 //   this.readTslip()
}

// const PlansTable = ({productsTable}) => {
render() {
    const isMobile = window.innerWidth < 800
    return (
        <div className={`PlansTable pagePadding fadeIn ${isMobile ? 'paddingTopL paddingBottomL' : 'paddingTopXL paddingBottomXL'}`}>
            <div className={`tableWrap ${isMobile ? '' : 'paddingTopL'}`}>
                <div className='underLine primaryStrongBackground'></div>
                <table className={isMobile ? 'fontSizeS' : 'fontSizeXS'}>
                    <thead>
                        <tr>
                            {plans.map((plan, i) => (
                                <th
                                    className={`paddingS fontSizeM`}
                                    key={i}
                                >
                                    {plan}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.productsPlan.map((product) => <ProductRow product={product}/>) }
                     </tbody> 
                </table>
                <div className='centerText fontSizeXS italic'>
                    <p>
                    **Non-Resident Program: Includes work permit and student visas. Only applicable for purchases made before December 31, 22 due to the Prohibition on the Purchase of Residential Property by Non-Canadians Act
                </p>
                </div>
            </div>
            
            <div className='centerText paddingTopL'>
                <OnScrollAnimatior
                    animation='fadeInUp'
                    isMobile = {isMobile}
                    preAnimationStyles={{ opacity: 0 }}
                >
                     <Button
                        onClick={() => {
                             let url = 'https://bit.ly/3jnotxM'
                            let a = document.createElement('a')
                            a.href = url
                            a.download = url.split('/').pop()
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        }}
                        backgroundClassName='darkerBackground'
                    >
                        Download e-catalogue
                </Button>
                   
{/*                 <button onClick={() => FileDownload(this.state.fileData, "test.pdf")}> Download T-slip</button>
 */}                 </OnScrollAnimatior>
            </div>
        </div>
    )
}
}
export default pure(PlansTable)