import React from 'react'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { Button } from '../../Atoms'
import image from './image.png'
import './BlogPostCard.css'

const BlogPostCard = props => (
    <div className='BlogPostCard paddingRightM paddingLeftM'>
        <div className='imageWrap'>
            <img src={image} alt='' />
        </div>
        <div className='textWrap'>
            <div className='bold fontSizeM paddingTopM'>1/5/2019</div>
            <div className='bold fontSizeM paddingTopM'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
            <div className='fontSizeXS paddingTopS paddingBottomM'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </div>
            <Link to='/blogpost/1'>
                <Button backgroundClassName={props.buttonBackgroundClassName || 'darkBackground'} />
            </Link>
        </div>
    </div>
)

export default pure(BlogPostCard)