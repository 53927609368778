import React from 'react'
import { pure } from 'recompose'
import { H1 } from '../../../../Atoms'
import './Header.css'

const Header = props => (
    <div className='Header withBackground center lightText centerText pagePadding'>
        <div>
            <H1 className='fadeIn'>Loyalty Program</H1>
            <div className='paddingTopM fontSizeM fadeInUp'>
                Earn great rewards with Power Agent status
            </div>
        </div>

    </div>
)

export default pure(Header)