import React from 'react'
import { pure } from 'recompose'
import { OnScrollAnimatior } from '../../../../../../Molecules'
import './SingleListItem.css'

const SingleListItem = props => {

    const isMobile = window.innerWidth < 800

    const circleElem = <div className='icon primaryStrongBackground'></div>

    return (
        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
            <div className={`SingleListItem lineHeightM paddingTopL paddingBottomL ${isMobile ? '' : 'paddingRightL paddingLeftL'} row borderPrimaryStrong ${props.noBorder ? 'noBorder' : ''}`}>
                {!isMobile ? circleElem : <span></span>}
                <div>
                    <div className={`${isMobile ? '' : 'paddingLeftM paddingTopS'} titleWrap fontSizeM bold`}>
                        {isMobile ? circleElem : <span></span>}
                        {props.title}
                    </div>
                    <div className={`${isMobile ? 'paddingLeftM fontSizeS' : 'paddingTopM paddingLeftXL fontSizeXS'}`}>
                        {props.text}
                    </div>
                </div>
            </div>
        </OnScrollAnimatior>
    )
}

export default pure(SingleListItem)