import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../Atoms";
import image from "../how-to-invest.png";
import "./ProductCard.css";

const defaultValues = {
    image,
    title: "Bank-level Service",
    text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
};

const ProductCard = props => (
    <div className="ProductCard">
        <div className="row">
            <img className="roundedTopBig" src={props.image || image} alt="" />
        </div>
        <div className="textWrap">
            <div className="innerContent paddingM">
                <H2 className="fontSizeMM lineHeightM">{props.title || defaultValues.title}</H2>
                <div className="fontSizeXS lineHeightM bodyText fontWeightNormal">
                    {props.text || defaultValues.text}
                </div>
            </div>
        </div>
    </div>
);

export default pure(ProductCard);
