import React from 'react'
import { pure } from 'recompose'
import { OnScrollAnimatior } from '../../../../Molecules'
import user from './user.svg'
import './TeamMember.css'

const defaultValues = {
    image: '',
    name: 'Name Here',
    title: 'Title Here',
    bio: 'Bio Here'
}

const TeamMember = props => {
    const isMobile = window.innerWidth < 800
    console.log(props.image);
    return (
        <div className={`TeamMember row ${props.big ? 'big' : ''} ${isMobile ? 'paddingBottomL' : ''}`}>
            <OnScrollAnimatior animation='pulse'>
                <div className='imageWrap center'>
                    <img src={`${props.image || defaultValues.image}`} alt='' />
                </div>
            </OnScrollAnimatior>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <div className={`textWrap lineHeightM ${isMobile && props.big ? 'paddingTopM' : 'paddingLeftM paddingRightM paddingTopL'}`}>
                    <b className='fontSizeM mobileCenter'>
                        {props.name || defaultValues.name}
                    </b>
                    <div className='paddingTopS fontSizeS mobileCenter'>
                        {props.title || defaultValues.title}
                    </div>
                    {
                        props.education ?
                            <div className='paddingTopXS fontSizeS mobileCenter'>
                                {props.education}
                            </div> :
                            <span></span>
                    }
                    <div className='paddingTopS fontSizeXS bio'>
                        {props.bio || defaultValues.bio}
                    </div>
                </div>
            </OnScrollAnimatior>
        </div>
    )
}

export default pure(TeamMember)